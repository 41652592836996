import React, { useState, useEffect }  from 'react'
import useAuth from '../../hooks/useAuth'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./profilenpasswordchange.scss"

import { Formik, Form, Field } from 'formik'
import { Row, Col, Container } from 'react-bootstrap'
import moment from 'moment'
import Logo from '../../images/Logo'

const imgLogo = Logo()
let config = ""

const ProfileNPasswordChange = () => {

  window.scrollTo(0, 0)
  const { auth } = useAuth()

  const apiUrlDW = process.env.REACT_APP_API_KEY + "/dw-user"

  const idUser = auth.authIdUser
  const [userData, setUserData] = useState('')
  const [errPesan, setErrPesan] = useState('')
  const initialValues = {
    newPassword: '',
    confirmPassword: ''
  }

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const fetchUser = (idUser) => {
    config = {
      method : "get",
      url : `${apiUrlDW}/${idUser}`,
      headers : {
        "Content-Type":"application/json"
      }
    }
    return axiosPrivate(config)
  }

  const useUserDetail = (idUser, onSuccess, onError) => {
    return useQuery(
      ['user', idUser], 
      () => fetchUser(idUser), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat mengakses data user !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const userDetail = data.data[0]
          return userDetail
        }
      }
    )
  }    

  const { isLoading, data: userDetail } = useUserDetail(idUser)
  
  useEffect(() => {
    userDetail && setUserData(userDetail)
  }, [userData, isLoading, userDetail])

  const updateUser = (input) => {
    config = {
      method : "patch",
      url : `${apiUrlDW}/change/${idUser}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        password: input.newPassword
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateUser = () => {
    return useMutation(
      updateUser, 
      {
        onError: () => {
          toast.error('Password gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          setErrPesan('Password telah diubah ...')
          // queryClient.invalidateQueries()
          queryClient.resetQueries()
        },
      }
    )
  }

  const { mutate: updateUsr } = useUpdateUser()

  const onProses = (input) => {
    if (input.newPassword === '') 
      setErrPesan('Password tidak boleh kosong ...')
    else if (input.newPassword !== input.confirmPassword)
      setErrPesan('Password tidak sama ...')
    else
      setErrPesan('')
    if (errPesan === '') {
      updateUsr(input)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
    >
      {formik => {
        return (
          <Container className="w-100">
            <Form>
              { isLoading &&
                <>
                  <div className="w-100 d-flex mt-3 justify-content-center">
                    <Row className="w-25 justify-content-center">
                      <img src={imgLogo} width="15%" className="rounded-circle img-thumbnail border-danger" alt="GKJ"></img>
                    </Row>
                  </div>
                  <div className="w-100 mt-3 justify-content-center">
                    <Row className="text-center">
                      <strong> Loading ... </strong>
                    </Row>
                    <Row className="mb-3 justify-content-center">
                      <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                    </Row>
                  </div>
                </>
              }
              { userData &&
                <div className="accordion w-100" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" 
                        data-bs-target="#panelsStayOpen-collapseOne" 
                        aria-expanded="true" aria-controls="panelsStayOpen-collapseOne"
                      >
                        <span className="text-primary fw-bold"> <i className="bi bi-person-circle"></i> </span> &nbsp;&nbsp;
                        Profile User <span className="text-primary fw-bold"> <i className="bi bi-caret-right"></i> </span> &nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>{userData.namaDepan}</strong>&nbsp;&nbsp;<strong>{userData.namaBelakang}</strong> &nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                      <div className="accordion-body">
                        <p>
                          <strong><i className="bi bi-file-person-fill text-primary"></i>&nbsp; ID </strong>&nbsp; : &nbsp;
                            <code>{userData.idUser}</code>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong><i className="bi bi-envelope-open"></i>&nbsp;</strong>&nbsp; : &nbsp;
                            <code>{userData.userEmail}</code>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <strong><i className="bi bi-lock-fill text-success"></i>&nbsp;Level</strong>&nbsp; : &nbsp;
                            <code>{userData.levelUser}</code>
                        </p>
                        <hr/>
                        <p>
                          <i className="bi bi-house-fill text-primary"></i>&nbsp; : &nbsp;
                            {userData.alamat === null ? '-' : userData.alamat}&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                          <i className="bi bi-telephone-fill text-primary"></i>&nbsp; : &nbsp;
                            {userData.mobileNo === null ? '-' : userData.mobileNo}
                        </p>
                        <p>
                          <i className="bi bi-check2-circle"></i>&nbsp;Status : &nbsp;
                            {userData.status}
                          &nbsp;&nbsp;&nbsp;---<i className="bi bi-arrow-right-circle-fill text-success"></i>---&nbsp;&nbsp;&nbsp;
                          <i className="bi bi-calendar2-date-fill text-primary"></i> &nbsp;&nbsp;&nbsp;
                            {moment(userData.tanggalStatus).format('DD-MMMM-YYYY')}&nbsp;&nbsp;&nbsp;
                        </p>
                        <p>
                          <i className="bi bi-journal-text"></i>&nbsp; Keterangan : &nbsp;
                            {userData.keterangan === null ? '-' : userData.keterangan}
                        </p>
                        {/* <p>
                          Foto : {userData.foto} kalo begini saja error, hrs jadi object dulu
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item w-100">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" 
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Change Password
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                      <div className="accordion-body w-100">
                        <Row>
                          <Col className="w-25">
                            <label htmlFor="newPassword"> Password Baru </label>
                          </Col>
                          <Col className="w-75">
                            <Field id="newPassword" name="newPassword" type="password" />
                          </Col>
                        </Row>
                        <hr/>
                        <Row>
                          <Col className="w-25">
                            <label htmlFor="confirmPassword"> Ketik Ulang Password </label>
                          </Col>
                          <Col className="w-75">
                            <Field id="confirmPassword" name="confirmPassword" type="password" />
                          </Col>
                        </Row>
                        <hr/>
                        <Row className="form-group">
                          <button 
                            type="button" 
                            onClick={() => onProses(formik.values)} 
                            className="btn btn-primary justify-content-center"
                          > Ubah Password </button>
                        </Row>
                        {errPesan 
                          ? 
                            <Row>
                              <hr/>
                              <div>
                                {errPesan === 'Password telah diubah ...' 
                                  ? <i className="bi bi-patch-check-fill text-success"></i> 
                                  : <i className="bi bi-patch-exclamation-fill text-danger"></i> 
                                }
                                &nbsp;&nbsp;&nbsp; {errPesan} 
                              </div>
                            </Row> 
                          : ''
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Form>
          </Container>
        )
      }} 
    </Formik> 
  )
}

export default ProfileNPasswordChange