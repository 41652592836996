import React, { useState, useEffect } from "react"
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import TextError from '../../components/form/TextError'
import CustomSelect from "../../components/form/CustomSelect"

require('moment/locale/id')

let config = ""
let nmApl = "" 

const EditUserAkses = ({onClickHandleEdit, idGereja, idBakowi, idAplikasi, idUserMaint, nomorIdUser, usrList}) => {
  
  window.scrollTo(0, 0)

  const apiUrlDW = process.env.REACT_APP_API_KEY + "/dw-user"

  const [userList, setUserList] = useState([])

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()
  
  const addUserAkses = (input) => {
    config = {
      method : "post",
      url : `${apiUrlDW}/userakses`,
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        idUser: input.idUser,
        idAplikasi: input.idAplikasi,
        levelAkses: input.levelAkses,
        statusAkses: input.statusAkses,
        userAuthorize: input.userAuthorize
      }
    }
    return axiosPrivate(config)
  }

  const useAddUserAkses = () => {
    return useMutation(
      addUserAkses, 
      {
        onError: () => {
          toast.error('Data akses user gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data akses user berhasil ditambah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.resetQueries()
        },
      }
    )
  }

  const updateUserAkses = (input) => {
    config = {
      method : "patch",
      url : `${apiUrlDW}/userakses/${input.idUser}/${input.idAplikasi}`,
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        levelAkses: input.levelAkses,
        statusAkses: input.statusAkses,
        tanggalStatus: input.tanggalStatus,
        userAuthorize: input.userAuthorize
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateUserAkses = () => {
    return useMutation(
      updateUserAkses, 
      {
        onError: (err) => {
          toast.error('Data akses user gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data akses user berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.resetQueries()
        },
      }
    )
  }

  useEffect(() => {
    usrList && setUserList(usrList)
  }, [usrList])

  const statusUserAksesOpt = [
    {value: 'Aktif', label: 'Aktif'},
    {value: 'Tunda', label: 'Tunda'},
    {value: 'Keluar', label: 'Keluar'},
    {value: 'Lainnya', label: 'Lainnya'},
  ]

  switch (idAplikasi.toUpperCase()) {
    case "GL":
      nmApl = "General Ledger" 
      break
    case "DW":
      nmApl = "Data Warga"
      break
    case "IA":
      nmApl = "Inventaris Aset" 
      break
    case "PP":
      nmApl = "Penerimaan Pengeluaran"
      break
    case "AG":
      nmApl = "Aktivitas Gereja" 
      break
    case "SP":
      nmApl = "Simpan Pinjam"
      break
    default:
      nmApl = "***"
      break
  }

  let menuScreenClassName = "w-100 justify-content-end text-center border-0 fw-bold text-light"
  let btnTambahClassName = "btn btn-outline-primary btn-sm fst-italic fw-bold"
  let btnUbahClassName = "btn btn-outline-success btn-sm fst-italic fw-bold"
  let btnConfirmClassName = "btn btn-sm fst-italic fw-bold text-light"
  let btnCancelClassName = "btn btn-dark btn-sm fst-italic fw-bold text-light"

  const initialValues = {
    idUserMaint: idUserMaint, 
    idGereja: idGereja, 
    idAplikasi: idAplikasi, 
    namaAplikasi: nmApl, 
    idUser: nomorIdUser ? parseInt(nomorIdUser.idUser, 10) : "", 
    namaUser: nomorIdUser ? nomorIdUser.namaUser : "", 
    statusUser: nomorIdUser ? nomorIdUser.statusUser : "", 
    levelAkses: nomorIdUser ? nomorIdUser.levelAkses : "", 
    statusAkses: nomorIdUser ? nomorIdUser.statusAkses : "", 
    userAuthorize: 
      nomorIdUser 
        ? nomorIdUser.userAuthorize 
          ? nomorIdUser.userAuthorize 
          : '*' 
        : idUserMaint, 
    namaUserAuthorize: 
      nomorIdUser && nomorIdUser.namaUserAuthorize 
        ? nomorIdUser.namaUserAuthorize 
        : '*', 
    aktivitas: 
      nomorIdUser && nomorIdUser.aktivitas 
        ? nomorIdUser.aktivitas 
        : '*', 
    tanggalAktivitas: 
      nomorIdUser && nomorIdUser.tanggalAktivitas 
        ? nomorIdUser.tanggalAktivitas 
        : '*', 
    menuScreen: nomorIdUser ? "Idle" : "A D D",
    menuClass: menuScreenClassName.concat(nomorIdUser ? " bg-info" : " bg-primary"),
    fieldDisableKey: nomorIdUser ? true : false,
    fieldDisable: nomorIdUser ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorIdUser ? "" : " invisible"),
    btnUbahClass: btnUbahClassName.concat(nomorIdUser ? "" : " invisible"),
    btnConfirmClass: btnConfirmClassName.concat(nomorIdUser ? " invisible" : " bg-primary visible"),
    btnCancelClass: btnCancelClassName.concat(nomorIdUser ? " invisible" : " bg-primary visible"),
    btnConfirmText: nomorIdUser ? "" : "Tambahkan data ...",
  }

  const { mutate: addUsrAkss } = useAddUserAkses()
  const { mutate: updateUsrAkss } = useUpdateUserAkses()
  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
        addUsrAkss(input)
        onClickHandleEdit(false)
    }
    if (input.menuScreen === "UPDATE") {
        updateUsrAkss(input)
        onClickHandleEdit(false)
    }
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={EditUserAksesValidationSchema}
      enableReinitialize
    >
      {formik => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue('menuScreen', 'Idle')
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'visible'))
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // show buttonTU => 'FF'
          formik.setFieldValue('btnMenuDisable', false)
          // hidden button konfirmasi
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'invisible'))
          // disable semua field
          formik.setFieldValue("fieldDisableKey", true)
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue('idUser', '')
          formik.setFieldValue('namaUser', '')
          formik.setFieldValue('statusUSer', '')
          formik.setFieldValue('levelAkses', '')
          formik.setFieldValue('statusAkses', '')
          formik.setFieldValue('userAuthorize', idUserMaint)
          formik.setFieldValue('namaUserAuthorize', '')
          formik.setFieldValue('aktivitas', '')
          formik.setFieldValue('tanggalAktivitas', moment().format("YYYY-MM-DD"))
          formik.setFieldValue('menuScreen', 'A D D')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Tambahkan data ...')
          // enable semua field
          formik.setFieldValue("fieldDisableKey", false)
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue('userAdd', idUserMaint)
          formik.setFieldValue('menuScreen', 'UPDATE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTU
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Ubah data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        return (
          <div className="container">
            <Form className="w-100 ms-2">
              <Row className="fw-bold mb-2">
                <Field name="idUserMaint" type="hidden" />
                <Field name="idGereja" type="hidden" />
                <Field name="idBakowi" type="hidden" />
                <Field name="fieldDisableKey" type="hidden" />
                <Field name="fieldDisable" type="hidden"/>
                <Field name="menuClass" type="hidden"/>
                <Field name="btnTambahClass" type="hidden"/>
                <Field name="btnUbahClass" type="hidden"/>
                <Field name="btnMenuDisable" type="hidden"/>
                <Field name="btnConfirmClass" type="hidden"/>
                <Field name="btnCancelClass" type="hidden"/>
                <Field name="btnConfirmText" type="hidden"/>
                <Col md={10} sm={6} xs={6}>
                  <p className="mb-2">
                    <label className="text-primary" htmlFor="namaAplikasi"> Aplikasi </label>
                    <Field className="ms-3" id="namaAplikasi" name="namaAplikasi" disabled={true}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    <span className="text-primary fw-bold"> 
                      <i className="bi bi-person-circle"></i> 
                    </span> &nbsp; 
                    Nama User &nbsp; : &nbsp; <strong>{formik.values.namaUser}</strong> 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    ID# &nbsp; : &nbsp; <strong>{formik.values.idUser}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    Status &nbsp; : &nbsp; <strong>{formik.values.statusUser}</strong>
                  </p>
                </Col>
                <Col md={2} sm={2} xs={2}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold justify-content-between">
                <Col className="form-group col-8">
                  <label className="text-primary w-10" htmlFor="idUser"> Nama </label>
                  <CustomSelect
                    options={userList}
                    value={formik.values.idUser}
                    placeholder="Ketik / Pilih => Nomor / Nama User"
                    onChange={(value) => {
                      formik.setFieldValue("idUser", value.value.value);
                      formik.setFieldValue("namaUser", value.value.namaUser);
                      formik.setFieldValue("statusUser", value.value.statusUser)
                    }}
                    onBlur={() => {}}
                    disableOpt={(userList) => userList.statusUser !== "Aktif"}
                    disableSelect={formik.values.fieldDisableKey}
                    clearable={false}
                    className="mb-1"
                  />
                  <ErrorMessage name="idUser" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group col-2">
                  <Row className="justify-content-center">
                    <label className="text-primary w-30" htmlFor="levelAkses"> Level </label>
                  </Row>
                  <Row className="justify-content-center">
                    <Field className="text-center w-25" id="levelAkses" name="levelAkses" disabled={formik.values.fieldDisable}/>
                  </Row>
                  <ErrorMessage name="levelAkses" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group col-2">
                  <label className="text-primary w-90" htmlFor="statusAkses"> Status Akses </label>
                  <CustomSelect
                    options={statusUserAksesOpt}
                    value={formik.values.statusAkses}
                    placeholder="Status"
                    onChange={(value) => formik.setFieldValue("statusAkses", value?.value?.value ? value?.value?.value : null)}
                    onBlur={() => {}}
                    // disableOpt={}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1"
                  />
                  <ErrorMessage name="statusAkses" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold small">
                <p>
                  <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                    Maintenance : {formik.values.aktivitas} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Id User Maint : {formik.values.userAuthorize} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Tanggal Aktivitas : {moment(formik.values.tanggalAktivitas).format('LLLL')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small">
                <Col className="justify-content-start">
                  <button 
                    type="button" 
                    className={formik.values.btnConfirmClass}
                    disabled={
                      formik.values.idUser && 
                      parseInt(formik.values.levelAkses, 10) > 0 &&
                      parseInt(formik.values.levelAkses, 10) < 5 && 
                      formik.values.statusAkses 
                      ? false : true}
                    onClick={() => onClickConfirm(formik.values)}
                  > {formik.values.btnConfirmText} </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnCancelClass} 
                    onClick={() => formik.values.menuScreen === "UPDATE" ? screenIdle() : onClickHandleEdit(false)}
                  > Batal </button>
                </Col>
                <Col className="text-end">
                  <button 
                    type="button" 
                    className={formik.values.btnTambahClass} 
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnUbahClass} 
                    disabled={formik.values.btnMenuDisable} 
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button"
                    className='btn btn-outline-secondary btn-sm fst-italic fw-bold'
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>                                
              </Row>
              <hr/>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditUserAkses