import React, { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import useRefreshToken from "../hooks/useRefreshToken"
import useAuth from "../hooks/useAuth"
import useLocalStorage from "../hooks/useLocalStorage"
import { Row } from 'react-bootstrap'

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const { auth } = useAuth()
  const [persist] = useLocalStorage("persist", false)

  useEffect(() => {
    let isMounted = true

    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false)

    return () => (isMounted = false)
  }, [refresh, auth?.accessToken, persist])

  useEffect(() => {
    // console.log(`isLoading: ${isLoading}`)
    // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
  }, [isLoading])

  return (
    <>
      {!persist ? (
        <Outlet />
      ) : isLoading ? (
        <div className="w-100 mt-3 justify-content-center">
          <Row className="text-center">
              <strong> Loading ... </strong>
          </Row>
          <Row className="mb-3 justify-content-center">
              <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
          </Row>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default PersistLogin
