import * as Yup from "yup";

const nomorAnggotaRules = /[A-Z0-9]+$/;
const namaRules = /^[A-Z][\w+.' ']{1,49}$/;
const tempatRules = /^[A-Z][\w+.' ']{1,49}$/;
const hpRules = /\+?(?:[ -]?\d+)+|(\d+)(?:[ -]\d+)/;
const emailRules = /^[a-z0-9-_.+]+@[a-z0-9]+[a-z0-9-.]*\.[a-z0-9]{2,9}/;
const ktpRules = /^[0-9]{16}$/;
const jalanRules = /^[A-Z][\w+-/().' ']{1,74}$/;
const kodeposRules = /^[1-9][0-9]{4}$/;
const pribadiRules = /^[A-Z][\w+-/().' ']{1,49}$/;
const keteranganRules = /^[A-Z][\w+-/().' ']{1,49}$/;

export const EditUserValidationSchema = Yup.object().shape({
  userEmail: Yup.string()
    .max(50, "Maksimal 50 karakter.")
    .matches(emailRules, { message: "Format email tidak sesuai." })
    .required("email perlu diisi."),
  namaDepan: Yup.string()
    .required("Nama perlu diisi.")
    .max(50, "Maksimal 50 karakter.")
    .matches(namaRules, { message: "Dimulai dengan huruf besar." }),
  namaBelakang: Yup.string()
    .max(50, "Maksimal 50 karakter.")
    .matches(namaRules, { message: "Dimulai dengan huruf besar." }),
  levelUser: Yup.number()
    .integer("Diisi angka 1 - 4")
    .positive("Diisi angka 1 - 4")
    .lessThan(5, "Diisi angka 1 - 4"),
  mobileNo: Yup.string()
    .max(20, "Maksimal 20 karakter")
    .matches(hpRules, { message: "Isi dengan karakter '0-9'/'+'/' '/'-'." }),
});

export const ProfileAnggotaValidationSchema = Yup.object().shape({
  nomorAnggota: Yup.string()
    .required("Nomor anggota perlu diisi.")
    .max(20, "Maksimal 20 karakter.")
    .matches(nomorAnggotaRules, {
      message: "Isi dengan huruf besar dan angka.",
    }),
  namaDepan: Yup.string()
    .required("Nama perlu diisi.")
    .max(50, "Maksimal 50 karakter.")
    .matches(namaRules, { message: "Dimulai dengan huruf besar." }),
  namaBelakang: Yup.string()
    .max(50, "Maksimal 50 karakter.")
    .matches(namaRules, { message: "Dimulai dengan huruf besar." }),
  tempatLahir: Yup.string()
    .max(50, "Maksimal 50 karakter.")
    .matches(tempatRules, { message: "Dimulai dengan huruf besar." }),
  jenisKelamin: Yup.string()
    .required("Jenis kelamin perlu diisi.")
    .oneOf(["L", "P"], "diisi L/P."),
  idTipeAnggota: Yup.number().required("Tipe anggota perlu dipilih."),
  pengurus: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  pegawai: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
});

export const KontakWargaValidationSchema = Yup.object().shape({
  nomorTelepon: Yup.string()
    .max(20, "Maksimal 20 karakter")
    .matches(hpRules, { message: "Isi dengan karakter '0-9'/'+'/' '/'-'." }),
  email: Yup.string()
    .max(100, "Maksimal 100 karakter.")
    .matches(emailRules, { message: "Format email tidak sesuai." }),
  nomorKTP: Yup.string()
    .max(16, "Maksimal 16 digit.")
    .matches(ktpRules, "Isi dengan angka 16 digit."),
  alamatTinggal1: Yup.string()
    .max(75, "Maksimal 75 karakter.")
    .matches(jalanRules, { message: "Dimulai dengan huruf besar." }),
  alamatTinggal2: Yup.string()
    .max(75, "Maksimal 75 karakter")
    .matches(jalanRules, { message: "Dimulai dengan huruf besar." }),
  kodeposTinggal: Yup.string()
    .max(5, "Maksimal 5 digit")
    .matches(kodeposRules, "Diisi dengan 5 digit angka."),
});

export const PribadiWargaValidationSchema = Yup.object().shape({
  tempatBekerja: Yup.string()
    .max(50, "Maksimal 50 karakter")
    .matches(pribadiRules, { message: "Dimulai dengan huruf besar." }),
  jurusan: Yup.string()
    .max(50, "Maksimal 50 karakter")
    .matches(pribadiRules, { message: "Dimulai dengan huruf besar." }),
  namaSekolah: Yup.string()
    .max(50, "Maksimal 50 karakter")
    .matches(pribadiRules, { message: "Dimulai dengan huruf besar." }),
});

export const StatusAnggotaValidationSchema = Yup.object().shape({
  idStatusAnggota: Yup.number().required("Status anggota perlu dipilih"),
  keterangan: Yup.string()
    .max(100, "Maksimal 100 karakter")
    .matches(keteranganRules, { message: "Dimulai dengan huruf besar." }),
});

export const EditMajelisValidationSchema = Yup.object().shape({
  idIndukBakowi: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(65535, "Angka terlalu besar"),
  jabatan: Yup.string()
    .required("Nama jabatan perlu diisi")
    .max(50, "Maksimal 50 karakter"),
  idKategori: Yup.string().ensure().required("Kategori perlu dipilih"),
  deskripsi: Yup.string().max(100, "Maksimal 100 karakter"),
  idPenjabat: Yup.string().ensure().required("Penjabat perlu dipilih"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditBaptisValidationSchema = Yup.object().shape({
  nomorIndukWarga: Yup.string().required("Nama warga perlu dipilih"),
  tempatBaptis: Yup.string().max(100, "Maksimal 100 karakter"),
  pendetaInternal: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  namaPendeta: Yup.string().max(100, "Maksimal 100 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditSidiValidationSchema = Yup.object().shape({
  nomorIndukWarga: Yup.string().required("Nama warga perlu dipilih"),
  tempatSidi: Yup.string().max(100, "Maksimal 100 karakter"),
  pendetaInternal: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  namaPendeta: Yup.string().max(100, "Maksimal 100 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditNikahValidationSchema = Yup.object().shape({
  nomorIndukWarga: Yup.string().required("Nama warga perlu dipilih"),
  tempatNikah: Yup.string().max(100, "Maksimal 100 karakter"),
  pasanganInternal: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  namaPasangan: Yup.string().max(100, "Maksimal 100 karakter"),
  asalPasangan: Yup.string().max(100, "Maksimal 100 karakter"),
  pendetaInternal: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  namaPendeta: Yup.string().max(100, "Maksimal 100 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditKKValidationSchema = Yup.object().shape({
  nomorKK: Yup.string()
    .required("Nomor KK perlu diisi")
    .min(1, "Diisi 1-20 karakter")
    .max(20, "Diisi 1-20 karakter"),
  alamat1: Yup.string()
    .required("Alamat perlu diisi")
    .max(75, "Maksimal 75 karakter"),
  alamat2: Yup.string().max(75, "Maksimal 75 karakter"),
  kodepos: Yup.number()
    .typeError("Diisi hanya dengan angka")
    .min(5, "Diisi 5 angka")
    .max(5, "Diisi 5 angka")
    .test("len", "Diisi 5 angka", (val) => val.toString().length === 5),
  telepon: Yup.string().max(20, "Maksimal 20 karakter"),
  fax: Yup.string().max(20, "Maksimal 20 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditWilayahValidationSchema = Yup.object().shape({
  idBakowi: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(65535, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Nama wilayah perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  namaSingkat: Yup.string()
    .ensure()
    .required("Nama singkat wilayah perlu diisi")
    .max(20, "Maksimal 20 karakter"),
  pelayanan: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditKomisiValidationSchema = Yup.object().shape({
  idBakowi: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(65535, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Nama komisi perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  namaSingkat: Yup.string()
    .ensure()
    .required("Nama singkat komisi perlu diisi")
    .max(20, "Maksimal 20 karakter"),
  pelayanan: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditBadanValidationSchema = Yup.object().shape({
  idBakowi: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(65535, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Nama badan perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  namaSingkat: Yup.string()
    .ensure()
    .required("Nama singkat badan perlu diisi")
    .max(20, "Maksimal 20 karakter"),
  pelayanan: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditRTValidationSchema = Yup.object().shape({
  idBakowi: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(65535, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Nama rumah tangga perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  namaSingkat: Yup.string()
    .ensure()
    .required("Nama singkat rumah tangga badan perlu diisi")
    .max(20, "Maksimal 20 karakter"),
  pelayanan: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditOfficeValidationSchema = Yup.object().shape({
  idBakowi: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(65535, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Nama office (kantor) perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  namaSingkat: Yup.string()
    .ensure()
    .required("Nama singkat office (kantor) perlu diisi")
    .max(20, "Maksimal 20 karakter"),
  pelayanan: Yup.string()
    .oneOf(["Y", "T"], "diisi Y/T")
    .min(1, "hanya diisi Y/T")
    .max(1, "hanya diisi Y/T"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditKebaktianValidationSchema = Yup.object().shape({
  idTempatKebaktian: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(255, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Tempat Kebaktian perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditJabatanValidationSchema = Yup.object().shape({
  idKategoriJabatan: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(255, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Kategori Jabatan perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditPenghasilanValidationSchema = Yup.object().shape({
  idPenghasilan: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(255, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Kategori Penghasilan perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});

export const EditKelompokWilayahValidationSchema = Yup.object().shape({
  idWilayah: Yup.number()
    .required("Wilayah perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(65535, "Angka terlalu besar"),
  idKelompokWilayah: Yup.number()
    .required("Id perlu diisi")
    .typeError("Diisi hanya dengan angka")
    .integer("Diisi hanya dengan bilangan bulat")
    .positive("Diisi dengan angka positif")
    .lessThan(255, "Angka terlalu besar"),
  nama: Yup.string()
    .required("Nama kelompok perlu diisi")
    .max(100, "Maksimal 100 karakter"),
  keterangan: Yup.string().max(100, "Maksimal 100 karakter"),
});
