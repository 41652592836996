import React, { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import { Formik, Field, ErrorMessage } from "formik";
import TextError from "../../components/form/TextError";
import { Row, Col } from "react-bootstrap";
import useFormContext from "../../hooks/useFormContext";
import { ProfileAnggotaValidationSchema } from "../../components/data/ValidationSchema";
import {
  useAgamaList,
  useStatusPerkawinanList,
  useTipeAnggotaList,
  useWilayahList,
  useKelompokWilayahList,
} from "../../components/data/UseDataComponents";
import CustomSelect from "../../components/form/CustomSelect";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import id from "date-fns/locale/id";
import ControlledInput from "./ControlledInput";
import Effect from "./Effect";

registerLocale("id", id);

const Profile = () => {
  const { data, handleChange, setValidateProfile } = useFormContext();
  const profileRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  const { auth } = useAuth();
  const idKoperasi = auth.authIdKoperasi;

  const { data: agamaList } = useAgamaList();
  const { data: statusPerkawinanList } = useStatusPerkawinanList();
  const { data: tipeAnggotaList } = useTipeAnggotaList();
  const { data: wilayahList } = useWilayahList(idKoperasi);
  const { data: kelompokWilayahList } = useKelompokWilayahList(idKoperasi);

  const initialValues = {
    nomorAnggota: data.nomorAnggota, // mandatory
    namaDepan: data.namaDepan, // mandatory
    namaBelakang: data.namaBelakang,
    jenisKelamin: data.jenisKelamin, // mandatory
    tempatLahir: data.tempatLahir,
    tanggalLahir: data.tanggalLahir,
    idAgama: data.idAgama,
    agama: data.agama,
    idStatusPerkawinan: data.idStatusPerkawinan,
    statusPerkawinan: data.statusPerkawinan,
    tanggalDaftar: data.tanggalDaftar,
    idTipeAnggota: data.idTipeAnggota, // mandatory
    tipeAnggota: data.tipeAnggota,
    pengurus: data.pengurus,
    pegawai: data.pegawai,
    tanggalMasuk: data.tanggalMasuk,
    idWilayah: data.idWilayah,
    idKelompokWilayah: data.idKelompokWilayah,
    idStatusAnggota: data.idStatusAnggota,
    statusAnggota: data.statusAnggota,
    screenMode: data.screenMode,
  };

  const content = (
    <Formik
      initialValues={initialValues}
      validationSchema={ProfileAnggotaValidationSchema}
      innerRef={profileRef}
      validateOnMount
      enableReinitialize
    >
      {(formik) => {
        return (
          <div className="container w-100 mx-0 px-0">
            <Effect
              onChange={(currentFormikState, nextFormikState) => {
                setValidateProfile(formik.isValid);
              }}
            />
            <Row>
              <Col>
                <span className="text-success fw-bold">
                  {formik.values.namaDepan
                    ? formik.values.namaDepan.trim()
                    : ""}
                  {formik.values.namaBelakang
                    ? " ".concat(formik.values.namaBelakang.trim())
                    : ""}
                </span>
              </Col>
              <Col className="text-end">
                <span>
                  {" "}
                  <i className="bi bi-arrow-right-circle-fill text-info align-middle"></i>{" "}
                </span>
                <span
                  className={"text-end align-middle ".concat(
                    formik.values.idTipeAnggota > 0
                      ? "text-info"
                      : "text-danger"
                  )}
                >
                  {formik.values.tipeAnggota
                    ? " Tipe : ".concat(formik.values.tipeAnggota)
                    : ""}
                </span>
                &nbsp; &nbsp; &nbsp;
                <span>
                  {" "}
                  <i className="bi bi-arrow-right-circle-fill text-info align-middle"></i>{" "}
                </span>
                <span
                  className={"text-end align-middle ".concat(
                    formik.values.idStatusAnggota < 2
                      ? "text-info"
                      : "text-danger"
                  )}
                >
                  {formik.values.statusAnggota
                    ? " Status : ".concat(formik.values.statusAnggota)
                    : ""}
                </span>
              </Col>
            </Row>
            <hr className="mt-0 pt-0" />
            <Row className="mt-2">
              <Col md={3} sm={2} xs={2}>
                <form className="form-floating">
                  <Field
                    type="text"
                    className="form-control"
                    id="nomorAnggota"
                    name="nomorAnggota"
                    as={ControlledInput}
                    pattern="[A-Z0-9]{1,20}"
                    placeholder="<nomor induk>"
                    value={formik.values.nomorAnggota}
                    disabled={data.screenMode.match("Edit")}
                  />
                  <label htmlFor="nomorAnggota">
                    {" "}
                    No. Anggota <span className="text-danger fw-bold">
                      *
                    </span>{" "}
                  </label>
                  <ErrorMessage
                    name="nomorAnggota"
                    component={TextError}
                    className="text-warning"
                  />
                </form>
              </Col>
              <Col md={4} sm={2} xs={2} className="me-0 pe-0 form-group">
                <form className="form-floating">
                  <Field
                    type="text"
                    className="form-control"
                    id="namaDepan"
                    name="namaDepan"
                    as={ControlledInput}
                    pattern="([A-Z])[\w.' ']{1,49}"
                    placeholder="<nama depan>"
                    value={formik.values.namaDepan}
                  />
                  <label htmlFor="namaDepan">
                    {" "}
                    Nama Depan <span className="text-danger fw-bold">
                      *
                    </span>{" "}
                  </label>
                  <ErrorMessage
                    name="namaDepan"
                    component={TextError}
                    className="text-warning"
                  />
                </form>
              </Col>
              <Col md={3} sm={2} xs={2}>
                <form className="form-floating">
                  <Field
                    type="text"
                    className="form-control"
                    id="namaBelakang"
                    name="namaBelakang"
                    as={ControlledInput}
                    pattern="([A-Z])[\w+.' ']{1,49}"
                    placeholder="<nama belakang>"
                    value={formik.values.namaBelakang}
                  />
                  <label htmlFor="namaBelakang"> Nama Belakang </label>
                  <ErrorMessage
                    name="namaBelakang"
                    component={TextError}
                    className="text-warning"
                  />
                </form>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="form-group text-primary" md={3} sm={3} xs={3}>
                <label htmlFor="tempatLahir"> Tempat Lahir </label>
              </Col>
              <Col className="form-group" md={2} sm={2} xs={2}>
                <label htmlFor="tanggalLahir" className="mb-1 text-primary">
                  {" "}
                  Tanggal Lahir{" "}
                </label>
              </Col>
              <Col className="form-group" md={3} sm={3} xs={3}>
                <label htmlFor="jenisKelamin" className="mb-1 text-primary">
                  Jenis Kelamin <span className="text-danger fw-bold">*</span>
                </label>
              </Col>
              <Col className="form-group" md={2} sm={2} xs={2}>
                <label className="text-primary" htmlFor="idAgama">
                  {" "}
                  Agama{" "}
                </label>
              </Col>
              <Col className="form-group" md={2} sm={2} xs={2}>
                <label className="text-primary" htmlFor="idStatusPerkawinan">
                  {" "}
                  Status Perkawinan{" "}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <Field
                  type="text"
                  className="form-control"
                  id="tempatLahir"
                  name="tempatLahir"
                  as={ControlledInput}
                  pattern="([A-Z])[\w+.' ']{1,49}"
                  value={formik.values.tempatLahir}
                />
                <ErrorMessage
                  name="tempatLahir"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <DatePicker
                  selected={
                    (formik.values?.tanggalLahir &&
                      new Date(formik.values.tanggalLahir)) ||
                    null
                  }
                  value={
                    formik.values?.tanggalLahir &&
                    moment(formik.values?.tanggalLahir).format("DD-MMMM-YYYY")
                  }
                  locale="id"
                  onChange={(date) =>
                    handleChange({
                      value: date ? moment(date).format("YYYY-MM-DD") : "",
                      name: "tanggalLahir",
                    })
                  }
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  minDate={new Date("1910-01-01")}
                  maxDate={new Date()}
                  placeholderText="Select..."
                  dateFormat="yyyy-MM-DD"
                  peekNextMonth
                  dropdownMode="select"
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={100}
                  isClearable
                  className="date-picker-wrapper w-100"
                />
                <ErrorMessage
                  name="tanggalLahir"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
              <Col md={3} sm={3} xs={2}>
                <Row className="border border-primary rounded-3 mx-0 px-0 mb-1">
                  <Col md={6} className="my-1 px-0">
                    <div className="form-check form-check-inline ms-2">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="jenisKelamin"
                        id="lakilaki"
                        value="L"
                        onChange={handleChange}
                        checked={
                          formik.values.jenisKelamin === "L" ? true : false
                        }
                        disabled={false}
                      />
                      <label
                        className="form-check-label ms-1"
                        htmlFor="lakilaki"
                      >
                        Laki-laki
                      </label>
                    </div>
                  </Col>
                  <Col md={6} className="my-1 ms-0 ps-0">
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="jenisKelamin"
                        id="perempuan"
                        value="P"
                        onChange={handleChange}
                        checked={
                          formik.values.jenisKelamin === "P" ? true : false
                        }
                        disabled={false}
                      />
                      <label
                        className="form-check-label ms-1"
                        htmlFor="perempuan"
                      >
                        Perempuan
                      </label>
                    </div>
                  </Col>
                </Row>
                <ErrorMessage
                  name="jenisKelamin"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <CustomSelect
                  options={agamaList}
                  id={"idAgama"}
                  name="idAgama"
                  value={parseInt(formik.values.idAgama, 10)}
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <CustomSelect
                  options={statusPerkawinanList}
                  id={"idStatusPerkawinan"}
                  name="idStatusPerkawinan"
                  value={parseInt(formik.values.idStatusPerkawinan, 10)}
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="form-group" md={3} sm={3} xs={3}>
                <label className="text-primary" htmlFor="idTipeAnggota">
                  {" "}
                  Tipe Anggota <span className="text-danger fw-bold">
                    *
                  </span>{" "}
                </label>
              </Col>
              <Col className="form-group" md={2} sm={2} xs={2}>
                <label className="text-primary" htmlFor="idTanggalDaftar">
                  {" "}
                  Tanggal Daftar{" "}
                </label>
              </Col>
              <Col className="form-group" md={3} sm={3} xs={3}>
                <label className="text-primary" htmlFor="pegawai">
                  {" "}
                  Pegawai{" "}
                </label>
              </Col>
              <Col className="form-group" md={2} sm={2} xs={2}>
                <label className="text-primary" htmlFor="idTanggalMasuk">
                  {" "}
                  Tanggal Masuk{" "}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <CustomSelect
                  options={tipeAnggotaList}
                  id={"idTipeAnggota"}
                  name="idTipeAnggota"
                  value={parseInt(formik.values.idTipeAnggota, 10)}
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={false}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <DatePicker
                  selected={
                    (formik.values?.tanggalDaftar &&
                      new Date(formik.values.tanggalDaftar)) ||
                    null
                  }
                  value={
                    formik.values?.tanggalDaftar &&
                    moment(formik.values.tanggalDaftar).format("DD-MMMM-YYYY")
                  }
                  locale="id"
                  onChange={(date) =>
                    handleChange({
                      value: date ? moment(date).format("YYYY-MM-DD") : "",
                      name: "tanggalDaftar",
                    })
                  }
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  minDate={new Date("1910-01-01")}
                  maxDate={new Date()}
                  placeholderText="Select..."
                  dateFormat="yyyy-MM-DD"
                  peekNextMonth
                  dropdownMode="select"
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={100}
                  isClearable
                  className="date-picker-wrapper w-100"
                />
                <ErrorMessage
                  name="tanggalDaftar"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <CustomSelect
                  options={[
                    { value: "Y", label: "Ya" },
                    { value: "T", label: "Tidak" },
                  ]}
                  id={"pegawai"}
                  name="pegawai"
                  value={formik.values.pegawai}
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={false}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <DatePicker
                  selected={
                    (formik.values?.tanggalMasuk &&
                      new Date(formik.values.tanggalMasuk)) ||
                    null
                  }
                  value={
                    formik.values?.tanggalMasuk &&
                    moment(formik.values.tanggalMasuk).format("DD-MMMM-YYYY")
                  }
                  locale="id"
                  onChange={(date) =>
                    handleChange({
                      value: date ? moment(date).format("YYYY-MM-DD") : "",
                      name: "tanggalMasuk",
                    })
                  }
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  minDate={new Date("1910-01-01")}
                  maxDate={new Date()}
                  placeholderText="Select..."
                  dateFormat="yyyy-MM-DD"
                  peekNextMonth
                  dropdownMode="select"
                  showYearDropdown
                  showMonthDropdown
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={100}
                  isClearable
                  className="date-picker-wrapper w-100"
                />
                <ErrorMessage
                  name="tanggalMasuk"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="form-group" md={3} sm={3} xs={3}>
                <label className="text-primary" htmlFor="idWilayah">
                  {" "}
                  Wilayah{" "}
                </label>
              </Col>
              <Col className="form-group" md={3} sm={3} xs={3}>
                <label className="text-primary" htmlFor="idKelompokWilayah">
                  {" "}
                  Kelompok{" "}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={3} xs={3}>
                <CustomSelect
                  options={wilayahList}
                  id={"idWilayah"}
                  name="idWilayah"
                  value={parseInt(formik.values.idWilayah, 10)}
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <CustomSelect
                  options={
                    kelompokWilayahList &&
                    kelompokWilayahList.filter((wilayah) => {
                      return (
                        wilayah.idWilayah ===
                        parseInt(formik.values.idWilayah, 10)
                      );
                    })
                  }
                  id={"idKelompokWilayah"}
                  name="idKelompokWilayah"
                  value={parseInt(formik.values.idKelompokWilayah, 10)}
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
            </Row>
          </div>
        );
      }}
    </Formik>
  );

  return content;
};

export default Profile;
