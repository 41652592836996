import React, { createContext, useState } from "react";

const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const title = {
    0: "Profile",
    1: "Info Kontak",
    2: "Info Pribadi",
    3: "Status",
  };

  const [page, setPage] = useState(0);
  const [validateProfile, setValidateProfile] = useState(false);
  const [validateKontak, setValidateKontak] = useState(false);
  const [validatePribadi, setValidatePribadi] = useState(false);
  const [validateStatus, setValidateStatus] = useState(false);

  const [data, setData] = useState({
    nomorAnggota: "", // mandatory
    namaDepan: "", // mandatory
    namaBelakang: "",
    jenisKelamin: "", // mandatory
    tempatLahir: "",
    tanggalLahir: "",
    idAgama: "",
    idStatusPerkawinan: "",
    tanggalDaftar: "",
    idTipeAnggota: "", // mandatory
    pengurus: "",
    pegawai: "",
    tanggalMasuk: "",
    idWilayah: "",
    idKelompokWilayah: "",
    nomorTelepon: "",
    email: "",
    nomorKK: "",
    nomorKTP: "",
    idGolonganDarah: "",
    idPekerjaan: "",
    tempatBekerja: "",
    idPenghasilan: "",
    idPendidikan: "",
    jurusan: "",
    namaSekolah: "",
    tahunLulus: "",
    idStatusDalamKeluarga: "",
    idStatusAnggota: "", // mandatory
    tanggalStatusAnggota: "",
    keterangan: "",
    foto: "",
    tanggalDibuat: "",
    idUserPembuat: "",
    namaUserPembuat: "",
    tanggalDiubah: "",
    idUserPengubah: "",
    namaUserPengubah: "",
    screenMode: "Mode Tambah Data",
  });

  const handleChange = (e) => {
    const type = e.target?.type === undefined ? "object" : e.target.type;

    const name = e.target?.name === undefined ? e.name : e.target.name;

    const value =
      type === "checkbox"
        ? e.target.checked
        : e.target?.value
        ? e.target.value
        : e.value?.value
        ? e.value.value
        : e.value;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const {
    namaBelakang,
    tempatLahir,
    tanggalLahir,
    idAgama,
    idStatusPerkawinan,
    tanggalDaftar,
    tanggalMasuk,
    pengurus,
    pegawai,
    idWilayah,
    idKelompokWilayah,
    idKategoriMenjadiWarga,
    nomorTelepon,
    email,
    nomorKK,
    nomorKTP,
    idStatusTempatTinggal,
    idGolonganDarah,
    idPekerjaan,
    tempatBekerja,
    idPenghasilan,
    idPendidikan,
    jurusan,
    namaSekolah,
    tahunLulus,
    idStatusDalamKeluarga,
    tanggalStatusAnggota,
    keterangan,
    foto,
    idUserPembuat,
    tanggalDibuat,
    namaUserPembuat,
    idUserPengubah,
    tanggalDiubah,
    namaUserPengubah,
    screenMode,
    ...requiredInputs
  } = data;

  const canSubmit =
    [...Object.values(requiredInputs)].every(Boolean) &&
    validateProfile &&
    validateKontak &&
    validatePribadi &&
    validateStatus &&
    page === Object.keys(title).length - 1;

  const canNextPage1 = validateProfile;

  const canNextPage2 = validateKontak;

  const canNextPage3 = validatePribadi;

  const disablePrev = page === 0;

  const disableNext =
    page === Object.keys(title).length - 1 ||
    (page === 0 && !canNextPage1) ||
    (page === 1 && !canNextPage2) ||
    (page === 2 && !canNextPage3) ||
    page === 3;

  const prevHide = page === 0 && "remove-button";

  const nextHide = page === Object.keys(title).length - 1 && "remove-button";

  const submitHide = page !== Object.keys(title).length - 1 && "remove-button";

  return (
    <FormContext.Provider
      value={{
        title,
        page,
        setPage,
        data,
        setData,
        canSubmit,
        handleChange,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide,
        validateProfile,
        setValidateProfile,
        validateKontak,
        setValidateKontak,
        validatePribadi,
        setValidatePribadi,
        validateStatus,
        setValidateStatus,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
