import React from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";

import "./TopNav.css";

import Dropdowns from "../dropdown/Dropdown";
import ThemeMenu from "../thememenu/ThemeMenu";
// import { useAnggotaUltah, useWargaUltahNikah } from "../data/UseDataComponents";
import { useAnggotaUltah } from "../data/UseDataComponents";
import kop_image from "../../images/logo.png";
import user_image from "../../images/user.png";
import user_menu from "../../jsondata/user_menus.json";

const TopNav = () => {
  const { auth } = useAuth();
  const idKoperasi = auth.authIdKoperasi;
  const namaKoperasi = auth.authNamaKoperasi;
  const namaUser = auth.authNamaUser;
  const levelAkses = auth.authLevelAksesKP;

  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/", { replace: true });
  };

  const curr_kop = {
    display_name: <>Koperasi &nbsp; {namaKoperasi}</>,
    image: kop_image,
  };

  const curr_user = {
    display_name: namaUser,
    image: user_image,
  };

  const bulan = new Date().getMonth() + 1;

  const { data: anggotaUltah } = useAnggotaUltah(idKoperasi, bulan);
  // const { data: wargaUltahNikah } = useWargaUltahNikah(idKoperasi, bulan);
  const anggotaUltahHariIni =
    idKoperasi === undefined
      ? ""
      : anggotaUltah?.filter((item) => item.status === "hari ini");
  const wargaUltahNikahHariIni = "";
  //   idKoperasi === undefined
  //     ? ""
  //     : wargaUltahNikah?.filter((item) => item.status === "hari ini");
  const contentUltah =
    anggotaUltahHariIni?.length > 0
      ? anggotaUltahHariIni
      : [
          {
            content: "Hari ini tidak ada yang ulangtahun",
            icon: "bi bi-backspace-reverse-fill text-info",
          },
        ];
  const contentNikah =
    wargaUltahNikahHariIni?.length > 0
      ? wargaUltahNikahHariIni
      : [
          {
            content: "Hari ini tidak ada yang ulangtahun perkawinan",
            icon: "bi bi-backspace-reverse-fill text-info",
          },
        ];

  const renderItemUser = (item, index) => (
    <Dropdown.Item
      className={
        levelAkses <= item.level_akses
          ? "notification-item"
          : "notification-item-notallow"
      }
      as={levelAkses <= item.level_akses ? NavLink : "a"}
      to={item.route}
      key={index}
    >
      <i
        className={
          levelAkses <= item.level_akses
            ? item.icon
            : "bi bi-person-slash text-danger"
        }
      ></i>
      <span
        className={
          levelAkses <= item.level_akses ? "text-primary" : "text-secondary"
        }
      >
        {" "}
        {item.display_name}{" "}
      </span>
    </Dropdown.Item>
  );

  const renderNotificationItemUltahAnggota = (item, index) => (
    <Dropdown.Item className="notification-item" key={index}>
      <i
        className={
          item.icon.length > 0
            ? item.icon
            : "bi bi-backspace-reverse-fill text-secondary"
        }
      ></i>
      <span>
        {" "}
        {item.content.length > 0
          ? item.content
          : "Tidak ada anggota yg ultah hari ini"}{" "}
      </span>
    </Dropdown.Item>
  );

  const renderNotificationItemUltahNikah = (item, index) => (
    <Dropdown.Item className="notification-item" key={index}>
      <i
        className={
          item.icon.length > 0
            ? item.icon
            : "bi bi-backspace-reverse-fill text-secondary"
        }
      ></i>
      <span>
        {" "}
        {item.content.length > 0
          ? item.content
          : "Tidak ada warga yg ultah pernikahan hari ini"}{" "}
      </span>
    </Dropdown.Item>
  );

  return (
    <div className="topnav">
      <div className="topnav__left-kop pe-2">
        <div className="topnav__left-kop__image">
          <img src={curr_kop.image} alt="" />
        </div>
        <div className="topnav__left-kop__name">{curr_kop.display_name}</div>
      </div>
      <div className="topnav__right">
        <div className="topnav__right-item">
          <Dropdowns
            icon={"bi bi-person-circle fs-3 text-info"}
            badge={"-"}
            contentData={user_menu}
            renderItems={(item, index) => renderItemUser(item, index)}
          />
        </div>
        <div className="topnav__right-user__name">{curr_user.display_name}</div>
        <div className="topnav__right-item">
          <button className="btn-sm btn-primary" onClick={signOut}>
            <i className="bi bi-door-open text-warning"></i>
            <span> Sign Out </span>
          </button>
        </div>
        <div className="topnav__right-item">
          <Dropdowns
            icon={
              contentUltah && anggotaUltahHariIni?.length > 0
                ? "bi bi-bell fs-3 text-warning"
                : "bi bi-bell-slash-fill fs-3 text-secondary"
            }
            badge={
              contentUltah && anggotaUltahHariIni?.length > 0
                ? contentUltah.length
                : "-"
            }
            contentData={contentUltah}
            renderItems={(item, index) =>
              renderNotificationItemUltahAnggota(item, index)
            }
          />
        </div>
        <div className="topnav__right-item">
          <Dropdowns
            icon={
              contentNikah && wargaUltahNikahHariIni?.length > 0
                ? "bi bi-heart-half fs-4 text-danger"
                : "bi bi-heart-pulse-fill fs-3 text-secondary"
            }
            badge={
              contentNikah && wargaUltahNikahHariIni?.length > 0
                ? contentNikah.length
                : "-"
            }
            contentData={contentNikah}
            renderItems={(item, index) =>
              renderNotificationItemUltahNikah(item, index)
            }
          />
        </div>
        <div className="topnav__right-item">
          <ThemeMenu />
        </div>
      </div>
    </div>
  );
};

export default TopNav;
