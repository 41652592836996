import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Sidebar.css";
import useAuth from "../../hooks/useAuth";
import logo from "../../images/logo.png";

import sidebar_items from "../../jsondata/sidebar_routes.json";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";

  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const SidebarItemNotAllow = (props) => {
  return (
    <div className="sidebar__item__notallow">
      <div className="sidebar__item__notallow-inner">
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const { auth } = useAuth();
  const levelAkses = auth.authLevelAksesKP;
  const activeItem = useLocation().pathname;

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="company logo" />
      </div>
      {sidebar_items.map((item, index) =>
        levelAkses <= item.level_akses ? (
          <NavLink to={item.route} key={index}>
            <SidebarItem
              title={item.display_name}
              icon={item.icon}
              active={item.route === activeItem}
            />
          </NavLink>
        ) : (
          <div className="text-secondary" key={index}>
            <SidebarItemNotAllow
              title={item.display_name}
              icon={"bi bi-person-slash text-danger"}
            />
          </div>
        )
      )}
    </div>
  );
};

export default Sidebar;
