import React from 'react'
import useFormContext from '../../hooks/useFormContext'
import "./progressbar.scss"

const ProgressBar = () => {
  const { page, title } = useFormContext()

  const interval = 100 / Object.keys(title).length

  const progress = ((page + 1) * interval).toFixed(0)

  const steps = Object.keys(title).map((step, i) => {
    return <div key={i} className="barmarker"> Step {i + 1} </div>
  })

  return (
    <section className='progress-container'>
      <div className='barmarker-container'>
        {steps} 
      </div>
      <progress className='progress' max="100" value={progress}></progress>
    </section>
  )
}

export default ProgressBar