import Profile from "./Profile";
import Kontak from "./Kontak";
import Pribadi from "./Pribadi";
import Status from "./Status";
import useFormContext from "../../hooks/useFormContext";

const FormInputs = () => {
  const { page } = useFormContext();

  const display = {
    0: <Profile />,
    1: <Kontak />,
    2: <Pribadi />,
    3: <Status />,
  };

  const content = <div className="form-inputs flex-col">{display[page]}</div>;

  return content;
};

export default FormInputs;
