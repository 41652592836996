import { useQuery } from "react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const apiUrlKP = process.env.REACT_APP_API_KEY + "/kop-components";

export const useAnggotaUltah = (nomorIdKoperasi, bulan, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotaultah", nomorIdKoperasi, bulan],
    () =>
      nomorIdKoperasi === undefined
        ? {}
        : axiosPrivate.get(
            `${apiUrlKP}/anggotaultah/${nomorIdKoperasi}/${bulan}`
          ),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaUltah = nomorIdKoperasi === undefined ? "" : data.data[0];
        return anggotaUltah;
      },
    }
  );
};

// export const useAnggotaUltahNikah = (
//   nomorIdKoperasi,
//   bulan,
//   onSuccess,
//   onError
// ) => {
//   const axiosPrivate = useAxiosPrivate();
//   return useQuery(
//     ["anggotaultahnikah", nomorIdKoperasi, bulan],
//     () =>
//       nomorIdKoperasi === undefined
//         ? {}
//         : axiosPrivate.get(
//             `${apiUrlKP}/anggotaultahnikah/${nomorIdKoperasi}/${bulan}`
//           ),
//     {
//       onSuccess,
//       onError,
//       select: (data) => {
//         const anggotaUltahNikah =
//           nomorIdKoperasi === undefined ? "" : data.data[0];
//         return anggotaUltahNikah;
//       },
//     }
//   );
// };

export const useAnggotaChart1 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotachart1", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/anggotachart1/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaChart1 = data.data[0];
        return anggotaChart1;
      },
    }
  );
};

export const useAnggotaChart3 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotachart3", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/anggotachart3/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaChart3 = data.data[0];
        return anggotaChart3;
      },
    }
  );
};

export const useAnggotaChart4 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotachart4", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/anggotachart4/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaChart4 = data.data[0];
        return anggotaChart4;
      },
    }
  );
};

export const useAnggotaChart5 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotachart5", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/anggotachart5/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaChart5 = data.data[0];
        return anggotaChart5;
      },
    }
  );
};

export const useAnggotaCard = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotacard", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/anggotacard/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaCard = data.data[0];
        return anggotaCard;
      },
    }
  );
};

export const useAnggotaUpdate = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotaupdate", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/anggotaupdate/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaUpdate = data.data[0];
        return anggotaUpdate;
      },
    }
  );
};

export const useChart15 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart15", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart15/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart15 = data.data[0];
        return chart15;
      },
    }
  );
};

export const useChart16 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart16", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart16/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart16 = data.data[0];
        return chart16;
      },
    }
  );
};

export const useChart23 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart23", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart23/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart23 = data.data[0];
        return chart23;
      },
    }
  );
};

export const useChart24 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart24", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart24/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart24 = data.data[0];
        return chart24;
      },
    }
  );
};

export const useChart25 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart25", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart25/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart25 = data.data[0];
        return chart25;
      },
    }
  );
};

export const useChart26 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart26", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart26/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart26 = data.data[0];
        return chart26;
      },
    }
  );
};

export const useChart31 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart31", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart31/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart31 = data.data[0];
        return chart31;
      },
    }
  );
};

export const useChart32 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart32", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart32/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart32 = data.data[0];
        return chart32;
      },
    }
  );
};

export const useChart33 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart33", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart33/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart33 = data.data[0];
        return chart33;
      },
    }
  );
};

export const useChart34 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart34", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart34/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart34 = data.data[0];
        return chart34;
      },
    }
  );
};

export const useChart35 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart35", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart35/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart35 = data.data[0];
        return chart35;
      },
    }
  );
};

export const useChart36 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart36", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart36/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart36 = data.data[0];
        return chart36;
      },
    }
  );
};

export const useChart41 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart41", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart41/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart41 = data.data[0];
        return chart41;
      },
    }
  );
};

export const useChart42 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart42", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart42/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart42 = data.data[0];
        return chart42;
      },
    }
  );
};

export const useChart43 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart43", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart43/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart43 = data.data[0];
        return chart43;
      },
    }
  );
};

export const useChart44 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart44", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart44/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart44 = data.data[0];
        return chart44;
      },
    }
  );
};

export const useChart45 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart45", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart45/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart45 = data.data[0];
        return chart45;
      },
    }
  );
};

export const useChart46 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart46", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart46/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart46 = data.data[0];
        return chart46;
      },
    }
  );
};

export const useChart47 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart47", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart47/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart47 = data.data[0];
        return chart47;
      },
    }
  );
};

export const useChart48 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart48", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart48/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart48 = data.data[0];
        return chart48;
      },
    }
  );
};

export const useChart51 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart51", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart51/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart51 = data.data[0];
        return chart51;
      },
    }
  );
};

export const useChart52 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart52", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart52/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart52 = data.data[0];
        return chart52;
      },
    }
  );
};

export const useChart53 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart53", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart53/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart53 = data.data[0];
        return chart53;
      },
    }
  );
};

export const useChart54 = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["chart54", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/chart54/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const chart54 = data.data[0];
        return chart54;
      },
    }
  );
};

export const useAnggotaProfile = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["infoanggotaprofile", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/infoanggotaprofile/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaProfile = data.data[0];
        return anggotaProfile;
      },
    }
  );
};

export const useAnggotaKontak = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["infoanggotakontak", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/infoanggotakontak/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaKontak = data.data[0];
        return anggotaKontak;
      },
    }
  );
};

export const useAnggotaPribadi = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["infoanggotapribadi", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/infoanggotapribadi/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaPribadi = data.data[0];
        return anggotaPribadi;
      },
    }
  );
};

export const useAnggotaStatus = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["infoanggotastatus", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/infoanggotastatus/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaStatus = data.data[0];
        return anggotaStatus;
      },
    }
  );
};

export const useAnggotaAll = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["infoanggotaall", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/infoanggotaall/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaAll = data.data[0];
        return anggotaAll;
      },
    }
  );
};

export const useAnggotaList = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["anggotalist", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/anggotalist/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggotaList = data.data[0];
        return anggotaList;
      },
    }
  );
};

export const useKategoriJabatanList = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["kategorijabatanlist", nomorIdKoperasi],
    () =>
      axiosPrivate.get(`${apiUrlKP}/kategorijabatanlist/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const kategoriJabatanList = data.data;
        return kategoriJabatanList;
      },
    }
  );
};

export const useWilayahList = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["wilayahlist", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/wilayahlist/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const wilayahList = data.data;
        return wilayahList;
      },
    }
  );
};

export const useKelompokWilayahList = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["kelompokwilayahlist", nomorIdKoperasi],
    () =>
      axiosPrivate.get(`${apiUrlKP}/kelompokwilayahlist/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const kelompokWilayahList = data.data;
        return kelompokWilayahList;
      },
    }
  );
};

export const useAgamaList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["agamalist"],
    () => axiosPrivate.get(`${apiUrlKP}/agamalist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const agamaList = data.data;
        return agamaList;
      },
    }
  );
};

export const useStatusPerkawinanList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["statusperkawinanlist"],
    () => axiosPrivate.get(`${apiUrlKP}/statusperkawinanlist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const statusPerkawinanList = data.data;
        return statusPerkawinanList;
      },
    }
  );
};

export const useTipeAnggotaList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["tipeanggotalist"],
    () => axiosPrivate.get(`${apiUrlKP}/tipeanggotalist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const tipeAnggotaList = data.data;
        return tipeAnggotaList;
      },
    }
  );
};

// export const useKategoriMenjadiAnggotaList = (onSuccess, onError) => {
//   const axiosPrivate = useAxiosPrivate();
//   return useQuery(
//     ["kategorimenjadianggotalist"],
//     () => axiosPrivate.get(`${apiUrlKP}/kategorimenjadianggotalist`),
//     {
//       onSuccess,
//       onError,
//       select: (data) => {
//         const kategoriMenjadiAnggotaList = data.data;
//         return kategoriMenjadiAnggotaList;
//       },
//     }
//   );
// };

export const useKKList = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["kklist", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/kklist/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const kkList = data.data[0];
        return kkList;
      },
    }
  );
};

export const usePropinsiList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["propinsilist"],
    () => axiosPrivate.get(`${apiUrlKP}/propinsilist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const propinsiList = data.data;
        return propinsiList;
      },
    }
  );
};

export const useKotaKabupatenList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["kotakabupatenlist"],
    () => axiosPrivate.get(`${apiUrlKP}/kotakabupatenlist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const kotaKabupatenList = data.data;
        return kotaKabupatenList;
      },
    }
  );
};

export const useGolonganDarahList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["golongandarahlist"],
    () => axiosPrivate.get(`${apiUrlKP}/golongandarahlist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const golonganDarahList = data.data;
        return golonganDarahList;
      },
    }
  );
};

export const usePekerjaanList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["pekerjaanlist"],
    () => axiosPrivate.get(`${apiUrlKP}/pekerjaanlist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const pekerjaanList = data.data;
        return pekerjaanList;
      },
    }
  );
};

export const usePenghasilanList = (nomorIdKoperasi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["penghasilanlist", nomorIdKoperasi],
    () => axiosPrivate.get(`${apiUrlKP}/penghasilanlist/${nomorIdKoperasi}`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const penghasilanList = data.data;
        return penghasilanList;
      },
    }
  );
};

export const usePendidikanList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["pendidikanlist"],
    () => axiosPrivate.get(`${apiUrlKP}/pendidikanlist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const pendidikanList = data.data;
        return pendidikanList;
      },
    }
  );
};

export const useStatusTempatTinggalList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["statustempattinggallist"],
    () => axiosPrivate.get(`${apiUrlKP}/statustempattinggallist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const statusTempatTinggalList = data.data;
        return statusTempatTinggalList;
      },
    }
  );
};

export const useStatusDalamKeluargaList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["statusdalamkeluargalist"],
    () => axiosPrivate.get(`${apiUrlKP}/statusdalamkeluargalist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const statusDalamKeluargaList = data.data;
        return statusDalamKeluargaList;
      },
    }
  );
};

export const useStatusAnggotaList = (onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery(
    ["statusanggotalist"],
    () => axiosPrivate.get(`${apiUrlKP}/statusanggotalist`),
    {
      onSuccess,
      onError,
      select: (data) => {
        const statusAnggotaList = data.data;
        return statusAnggotaList;
      },
    }
  );
};
