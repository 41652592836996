import axios from "../api/axios"
import useAuth from "./useAuth"

const useLogout = () => {
  const { setAuth } = useAuth()

  const logout = async () => {
    setAuth({})
    try {
      const response = await axios(process.env.REACT_APP_API_KEY + "/auth/logout", {
        withCredentials: true
      })
      console.log('res: ', response)
    } catch (err) {
      console.error('err: ', err)
    }
  }
  return logout
}

export default useLogout