import React, { useEffect } from "react"
import { Outlet } from 'react-router-dom'
import '../App.css'

import Sidebar from '../components/layout/Sidebar'
import TopNav from '../components/layout/TopNav'

import { useSelector, useDispatch } from 'react-redux'

import ThemeAction from '../redux/actions/ThemeAction'

const Layout = () => {

  const themeReducer = useSelector(state => state.ThemeReducer)

  const dispatch = useDispatch()

  useEffect(() => {
    const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')
    
    const colorClass = localStorage.getItem('colorMode', 'theme-mode-light')
    
    dispatch(ThemeAction.setMode(themeClass))
    
    dispatch(ThemeAction.setColor(colorClass))
  }, [dispatch])

  return (
    <main className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
      <Sidebar />
      <div className="layout__content">
        <TopNav />
        <div className="layout__content-main" />
        <Outlet />
      </div>

    </main>
  )
}

export default Layout