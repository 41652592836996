import React, { useState } from "react";
import useAuth from "../hooks/useAuth";

import { Row, Col } from "react-bootstrap";
import moment from "moment";
import ScrollButton from "../components/ScrollButton";

import {
  useAnggotaCard,
  useAnggotaChart1,
  useAnggotaChart3,
  useAnggotaChart4,
  useAnggotaChart5,
  useAnggotaUpdate,
  useAnggotaUltah,
} from "../components/data/UseDataComponents";

import Table from "../components/table/Table";
import Badge from "../components/badge/Badge";
import StatusCard from "../components/status-card/StatusCard";

import Chart from "react-apexcharts";

import { useSelector } from "react-redux";

const renderMutasiHead = (item, index) => <th key={index}>{item}</th>;

const renderMutasiBody = (item, index) => (
  <tr key={index}>
    <td>{item.nama}</td>
    <td>{item.mutasi}</td>
    <td>{moment(item.tanggal).format("DD-MMM-YYYY")}</td>
  </tr>
);

const ultahStatus = {
  "belum tiba": "primary",
  "sudah lewat": "warning",
  "hari ini": "danger",
};

const renderUltahHead = (item, index) => <th key={index}>{item}</th>;

const renderUltahBody = (item, index) => (
  <tr key={index}>
    <td>{item.noAnggota}</td>
    <td>{item.nama}</td>
    <td>{item.wilayah}</td>
    <td>{moment(item.tanggal).format("DD-MMM")}</td>
    <td>
      <Badge type={ultahStatus[item.status]} content={item.status} />
    </td>
  </tr>
);

const Dashboard = () => {
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);

  const { auth } = useAuth();

  const idKoperasi = auth.authIdKoperasi;
  const bulan = new Date().getMonth() + 1;

  const [pilChart, setPilChart] = useState("Chart1");

  const { data: anggotaCard } = useAnggotaCard(idKoperasi);
  const { data: anggotaChart1 } = useAnggotaChart1(idKoperasi);
  const { data: anggotaChart3 } = useAnggotaChart3(idKoperasi);
  const { data: anggotaChart4 } = useAnggotaChart4(idKoperasi);
  const { data: anggotaChart5 } = useAnggotaChart5(idKoperasi);
  const { data: anggotaUpdate } = useAnggotaUpdate(idKoperasi);
  const { data: anggotaUltah } = useAnggotaUltah(idKoperasi, bulan);

  const chart1Options = {
    series: [
      {
        name: "Jumlah Anggota",
        data: anggotaChart1?.slice(1).map((data) => data.jumlahAnggota),
      },
      {
        name: "Jumlah Keluarga",
        data: anggotaChart1?.slice(1).map((data) => data.jumlahKeluargaAnggota),
      },
      {
        name: "Jumlah KK",
        data: anggotaChart1?.slice(1).map((data) => data.jumlahKK),
      },
    ],
    options: {
      color: ["#6ab04c", "#2980b9", "#e0a912"],
      chart: {
        height: 380,
        type: "area",
        stacked: false,
      },
      dataLabels: { enabled: true },
      stroke: { curve: "smooth" },
      xaxis: {
        type: "category",
        categories: anggotaChart1
          ?.slice(1)
          .map((data) => data.namaSingkatWilayah),
        title: {
          text: "Wilayah",
        },
        tickPlacement: "between",
        labels: {
          show: true,
          style: {
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 800,
          },
        },
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        labels: {
          style: {
            colors: [],
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 800,
          },
        },
      },
      legend: { position: "top" },
      grid: { show: true },
    },
  };

  const chart2Options = {
    series: [
      {
        name: "Laki-laki",
        data: anggotaChart1?.slice(1).map((data) => data.L),
      },
      {
        name: "Perempuan",
        data: anggotaChart1?.slice(1).map((data) => data.P),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 440,
        stacked: true,
      },
      colors: ["#008FFB", "#FF4560"],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "70%",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return Math.abs(Math.round(val));
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },

      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        forceNiceScale: true,
        title: {
          text: "W i l a y a h",
          offsetX: 10,
        },
      },
      legend: {
        position: "top",
        // offsetX: 305,
        offsetX: 240,
        offsetY: -20,
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val) {
            return val;
          },
        },
        y: {
          formatter: function (val) {
            return Math.abs(val) + " Orang";
          },
        },
      },
      title: {
        text: "Komposisi Gender Anggota",
        style: {
          fontSize: "18px",
          fontWeight: 700,
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
      xaxis: {
        categories: anggotaChart1
          ?.slice(1)
          .map((data) => data.namaSingkatWilayah),
        title: {
          text: "Populasi",
        },
        labels: {
          formatter: function (val) {
            return Math.abs(Math.round(val)) + " Orang";
          },
        },
      },
    },
  };

  const chart3Options = {
    series: anggotaChart3?.map((data) => data.Jumlah),
    options: {
      colors: [
        "#008ffb",
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
        "#55b2f9",
        "#81f9d0",
        "#f2c672",
        "#f38b9a",
        "#b9abea",
        "#0c548a",
        "#02875a",
        "#a17f3e",
      ],
      labels: anggotaChart3?.map((data) => data.Kategori),
      title: {
        text: "Komposisi Usia Anggota",
        align: "right",
        margin: 10,
        offsetX: 10,
        offsetY: -7,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: 700,
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value.toString();
          },
        },
      },
      legend: {
        show: true,
        position: "right",
        offsetX: -20,
        offsetY: 50,
        markers: {
          width: 20,
          height: 20,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: -5,
          offsetY: 5,
        },
      },
      chart: {
        type: "donut",
        offsetX: -20,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return parseInt(val).toString(10) + "%";
        },
        background: {
          enabled: false,
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "#fff",
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10,
          },
          donut: {
            size: "50%",
            // background: 'transparent',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 500,
                // color: '#000000',
                offsetY: -10,
                formatter: function (val) {
                  return val;
                },
              },
              value: {
                show: true,
                fontSize: "20px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                // color: '#000000',
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                // showAlways: true,
                label: "Total",
                fontSize: "20px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                // color: '#373d3f',
                color: "#272625",
                formatter: function (w) {
                  return w.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
                },
              },
            },
          },
        },
      },
    },
  };

  const chart4Options = {
    series: [
      {
        name: "Anggota",
        data: anggotaChart4?.slice(1).map((data) => data.Anggota),
      },
      {
        name: "Keluarga",
        data: anggotaChart4?.slice(1).map((data) => data.Keluarga),
      },
      {
        name: "Pegawai",
        data: anggotaChart4?.slice(1).map((data) => data.Pegawai),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 440,
        stacked: false,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          dataLabels: {
            position: "top",
            // total: {
            //   enabled: false,
            //   style: {
            //     fontSize: "15px",
            //     fontWeight: 700,
            //   },
            // },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -50,
        },
      },
      xaxis: {
        categories: anggotaChart4
          ?.slice(1)
          .map((data) => data.namaSingkatWilayah),
        title: {
          text: "Wilayah",
        },
      },
      yaxis: {
        forceNiceScale: true,
        offsetY: -50,
        title: {
          text: "P o p u l a s i",
          offsetX: 0,
        },
      },
      legend: {
        position: "top",
        // offsetX: 104,
        offsetX: 0,
        offsetY: 0,
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: "Komposisi Personal",
        style: {
          fontSize: "18px",
          fontWeight: 700,
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
    },
  };

  const chart5Options = {
    series: [
      {
        name: "Laki-laki",
        data: anggotaChart5?.map((data) => data.L),
      },
      {
        name: "Perempuan",
        data: anggotaChart5?.map((data) => data.P),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 440,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: anggotaChart5?.map((data) => data.NamaBulan),
        title: {
          text: "Bulan",
        },
      },
      yaxis: {
        forceNiceScale: true,
        title: {
          text: "P o p u l a s i",
          offsetX: 0,
        },
      },
      legend: {
        position: "top",
        offsetX: 240,
        offsetY: -20,
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: "Bulan Ulang Tahun Anggota",
        style: {
          fontSize: "18px",
          fontWeight: 700,
          fontFamily: "Helvetica, Arial, sans-serif",
        },
      },
    },
  };

  const opt =
    pilChart === "Chart1"
      ? chart1Options.options
      : pilChart === "Chart2"
      ? chart2Options.options
      : pilChart === "Chart3"
      ? chart3Options.options
      : pilChart === "Chart4"
      ? chart4Options.options
      : chart5Options.options;

  const options =
    themeReducer === "theme-mode-dark"
      ? { ...opt, theme: { mode: "dark" } }
      : { ...opt, theme: { mode: "light" } };

  const series =
    pilChart === "Chart1"
      ? chart1Options.series
      : pilChart === "Chart2"
      ? chart2Options.series
      : pilChart === "Chart3"
      ? chart3Options.series
      : pilChart === "Chart4"
      ? chart4Options.series
      : chart5Options.series;

  const chartType =
    pilChart === "Chart1"
      ? "area"
      : pilChart === "Chart2"
      ? "bar"
      : pilChart === "Chart3"
      ? "donut"
      : "bar"; // Chart4 dan Chart5 sama tipe bar

  const mutasiTerakhir = {
    head: ["nama", "mutasi", "tanggal"],
    body: anggotaUpdate,
  };

  const ultahBulanIni = {
    header: ["No. Agt", "Nama", "Wilayah", "Tanggal", "Status"],
    body: anggotaUltah,
  };

  const statusCards = [
    {
      icon: "bi bi-person text-warning",
      count: anggotaCard && anggotaCard[0].jumlahAnggota,
      title: "Jumlah Anggota",
    },
    {
      icon: "bi bi-people text-info",
      count: anggotaCard && anggotaCard[0].jumlahKeluargaAnggota,
      title: "Jumlah Keluarga",
    },
    {
      icon: "bi bi-person-plus text-primary",
      count: anggotaCard && anggotaCard[0].jumlahPegawai,
      title: "Jumlah Pegawai",
    },
    {
      icon: "bi bi-house-fill text-secondary",
      count: anggotaCard && anggotaCard[0].jumlahKK,
      title: "Jumlah KK",
    },
    {
      icon: "bi bi-box-arrow-in-down text-success", // download
      count: anggotaCard && anggotaCard[0].mutasiMasuk,
      title: "Mutasi Masuk",
    },
    {
      icon: "bi bi-box-arrow-up text-danger", // upload
      count: anggotaCard && anggotaCard[0].mutasiKeluar,
      title: "Mutasi Keluar",
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <h2 className="page-header">Dashboard</h2>
        </Col>
        <Col className="d-flex justify-content-end">
          <button
            onClick={() => setPilChart("Chart1")}
            className="btn btn-sm my-3 btn-primary"
          >
            {" "}
            Jumlah KK{" "}
          </button>
          &nbsp;
          <button
            onClick={() => setPilChart("Chart2")}
            className="btn btn-sm my-3 btn-primary"
          >
            {" "}
            Profil Gender{" "}
          </button>
          &nbsp;
          <button
            onClick={() => setPilChart("Chart3")}
            className="btn btn-sm my-3 btn-primary"
          >
            {" "}
            Kategori Usia{" "}
          </button>
          &nbsp;
          <button
            onClick={() => setPilChart("Chart4")}
            className="btn btn-sm my-3 btn-primary"
          >
            {" "}
            Tipe Anggota{" "}
          </button>
          &nbsp;
          <button
            onClick={() => setPilChart("Chart5")}
            className="btn btn-sm my-3 btn-primary"
          >
            {" "}
            Bulan Ultah{" "}
          </button>
        </Col>
      </Row>
      <div className="row">
        <div className="col-6">
          <div className="row">
            {statusCards.map((item, index) => (
              <div className="col-6" key={index}>
                <StatusCard
                  icon={item.icon}
                  count={item.count}
                  title={item.title}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-6">
          <div className="card full-height">
            {/* chart */}
            {(pilChart === "Chart1" || pilChart === "Chart2"
              ? anggotaChart1
              : pilChart === "Chart3"
              ? anggotaChart3
              : pilChart === "Chart4"
              ? anggotaChart4
              : anggotaChart5) && (
              <Chart
                key={chartType}
                options={options}
                series={series}
                type={chartType}
                // height='100%'
                height="350"
                width="100%"
              />
            )}
          </div>
        </div>
        <div className="col-5">
          <div className="card">
            <div className="card__header">
              <h3>mutasi terakhir</h3>
            </div>
            <div className="card__body">
              {anggotaUpdate && (
                <Table
                  headData={mutasiTerakhir.head}
                  renderHead={(item, index) => renderMutasiHead(item, index)}
                  bodyData={mutasiTerakhir.body}
                  renderBody={(item, index) => renderMutasiBody(item, index)}
                />
              )}
            </div>
            <div className="card__footer">
              {/* <Link to='/'>view all</Link> */}
            </div>
          </div>
        </div>
        <div className="col-7">
          <div className="card">
            <div className="card__header">
              <h3>anggota ultah bulan ini</h3>
            </div>
            <div className="card__body">
              {anggotaUltah && (
                <Table
                  headData={ultahBulanIni.header}
                  renderHead={(item, index) => renderUltahHead(item, index)}
                  bodyData={ultahBulanIni.body}
                  renderBody={(item, index) => renderUltahBody(item, index)}
                />
              )}
            </div>
            <div className="card__footer">
              {/* <Link to='/'>view all</Link> */}
            </div>
          </div>
        </div>
      </div>
      <ScrollButton />
    </div>
  );
};

export default Dashboard;
