import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useField, useFormikContext } from 'formik'
import moment from 'moment'
import id from "date-fns/locale/id"

registerLocale("id", id)

const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props)
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            value={field.value && moment(field.value).format('DD-MMMM-YYYY')}
            locale='id'
            onChange={val => { 
                setFieldValue(field.name, val ? moment(val).format('YYYY-MM-DD') : '')
            }}
            maxDate={new Date()}
            minDate={new Date('1910-01-01')}
            dateFormat="yyyy-MM-DD"
            showYearDropdown
            showMonthDropdown
            scrollableMonthYearDropdown
            isClearable
        />
    )}

export default DatePickerField