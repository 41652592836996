import React, { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import { Formik, Field, ErrorMessage } from "formik";
import TextError from "../../components/form/TextError";
import { Row, Col } from "react-bootstrap";
import useFormContext from "../../hooks/useFormContext";
import { StatusAnggotaValidationSchema } from "../../components/data/ValidationSchema";
import { useStatusAnggotaList } from "../../components/data/UseDataComponents";
import CustomSelect from "../../components/form/CustomSelect";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import id from "date-fns/locale/id";
import ControlledInput from "./ControlledInput";
import Effect from "./Effect";

registerLocale("id", id);

const Status = () => {
  const statusRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { auth } = useAuth();
  const idKoperasi = auth.authIdKoperasi;

  const { data, handleChange, setValidateStatus } = useFormContext();

  const { data: statusAnggotaList } = useStatusAnggotaList();

  const initialValues = {
    idKoperasi: idKoperasi,
    idStatusAnggota: data.idStatusAnggota, // mandatory
    tanggalStatusAnggota: data.tanggalStatusAnggota,
    keterangan: data.keterangan,
    namaDepan: data.namaDepan,
    namaBelakang: data.namaBelakang,
    tipeAnggota: data.tipeAnggota,
    statusAnggota: data.statusAnggota,
    screenMode: data.screenMode,
  };

  const content = (
    <Formik
      initialValues={initialValues}
      validationSchema={StatusAnggotaValidationSchema}
      innerRef={statusRef}
      validateOnMount
      enableReinitialize
    >
      {(formik) => {
        return (
          <div className="container w-100 mx-0 px-0">
            <Effect
              onChange={(currentFormikState, nextFormikState) => {
                setValidateStatus(formik.isValid);
              }}
            />
            <span className="text-success fw-bold">
              {formik.values.namaDepan ? formik.values.namaDepan.trim() : ""}
              {formik.values.namaBelakang
                ? " ".concat(formik.values.namaBelakang.trim())
                : ""}
            </span>
            <span className="text-primary fw-bold">
              {formik.values.tipeAnggota
                ? " * Tipe Anggota: ".concat(formik.values.tipeAnggota)
                : ""}
            </span>
            <span
              className={
                formik.values.idStatusAnggota < 2
                  ? "text-info fw-bold"
                  : "text-danger fw-bold"
              }
            >
              {formik.values.statusAnggota
                ? " * Status : ".concat(formik.values.statusAnggota)
                : ""}
            </span>
            <hr className="mt-0 pt-0" />
            <Row className="mt-1">
              <Col md={3} sm={2} xs={2}>
                <label
                  className="form-group text-primary"
                  htmlFor="idStatusAnggota"
                >
                  {" "}
                  Status <span className="text-danger fw-bold">*</span>{" "}
                </label>
                <CustomSelect
                  options={statusAnggotaList}
                  value={parseInt(data.idStatusAnggota, 10)}
                  name="idStatusAnggota"
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={formik.values.fieldDisable}
                  clearable={false}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <label
                  className="form-group text-primary"
                  htmlFor="tanggalStatusAnggota"
                >
                  {" "}
                  Tanggal Status{" "}
                </label>
                <DatePicker
                  selected={
                    (formik.values?.tanggalStatusAnggota &&
                      new Date(formik.values.tanggalStatusAnggota)) ||
                    null
                  }
                  value={
                    formik.values?.tanggalStatusAnggota &&
                    moment(formik.values.tanggalStatusAnggota).format(
                      "DD-MMMM-YYYY"
                    )
                  }
                  locale="id"
                  onChange={(date) =>
                    handleChange({
                      value: date ? moment(date).format("YYYY-MM-DD") : "",
                      name: "tanggalStatusAnggota",
                    })
                  }
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  minDate={new Date("1910-01-01")}
                  maxDate={new Date()}
                  placeholderText="Select..."
                  dateFormat="yyyy-MM-DD"
                  peekNextMonth
                  dropdownMode="select"
                  showYearDropdown
                  showMonthDropdown
                  scrollableMonthYearDropdown
                  yearDropdownItemNumber={100}
                  isClearable
                  className="date-picker-wrapper w-100"
                />
                <ErrorMessage
                  name="tanggalStatusAnggota"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
            </Row>
            <hr />
            <Row className="mt-3">
              <Col md={8} sm={3} xs={3}>
                <label className="form-group text-primary" htmlFor="keterangan">
                  {" "}
                  Keterangan{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="keterangan"
                  name="keterangan"
                  as={ControlledInput}
                  // pattern="([A-Z])[\w+.' ']{1,99}"
                  placeholder="<keterangan>"
                  value={data.keterangan}
                />
                <ErrorMessage
                  name="keterangan"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
            </Row>
          </div>
        );
      }}
    </Formik>
  );

  return content;
};

export default Status;
