import React, { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import { Formik, Field, ErrorMessage } from "formik";
import TextError from "../../components/form/TextError";
import { Row, Col } from "react-bootstrap";
import useFormContext from "../../hooks/useFormContext";
import { KontakWargaValidationSchema } from "../../components/data/ValidationSchema";
import { useKKList } from "../../components/data/UseDataComponents";
import CustomSelect from "../../components/form/CustomSelect";
import ControlledInput from "./ControlledInput";
import Effect from "./Effect";

const Kontak = () => {
  const kontakRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { auth } = useAuth();
  const idKoperasi = auth.authIdKoperasi;

  const { data, handleChange, setValidateKontak } = useFormContext();

  const { data: kkList } = useKKList(idKoperasi);

  const initialValues = {
    idKoperasi: idKoperasi,
    nomorTelepon: data.nomorTelepon,
    email: data.email,
    nomorKK: data.nomorKK,
    nomorKTP: data.nomorKTP,
    namaDepan: data.namaDepan, // mandatory
    namaBelakang: data.namaBelakang,
    tipeAnggota: data.tipeAnggota,
    idStatusAnggota: data.idStatusAnggota,
    statusAnggota: data.statusAnggota,
    screenMode: data.screenMode,
  };

  const content = (
    <Formik
      initialValues={initialValues}
      validationSchema={KontakWargaValidationSchema}
      innerRef={kontakRef}
      validateOnMount
      enableReinitialize
    >
      {(formik) => {
        return (
          <div className="container w-100 mx-0 px-0">
            <Effect
              onChange={(currentFormikState, nextFormikState) => {
                setValidateKontak(formik.isValid);
              }}
            />
            <span className="text-success fw-bold">
              {formik.values.namaDepan ? formik.values.namaDepan.trim() : ""}
              {formik.values.namaBelakang
                ? " ".concat(formik.values.namaBelakang.trim())
                : ""}
            </span>
            <span className="text-primary fw-bold">
              {formik.values.tipeWarga
                ? " * Tipe Anggota: ".concat(formik.values.tipeAnggota)
                : ""}
            </span>
            <span
              className={
                formik.values.idStatusAnggota < 2
                  ? "text-info fw-bold"
                  : "text-danger fw-bold"
              }
            >
              {formik.values.statusAnggota
                ? " * Status : ".concat(formik.values.statusAnggota)
                : ""}
            </span>
            <hr className="mt-0 pt-0" />
            <Row className="mt-1 text-primary">
              <Col>
                <form className="form-floating">
                  <Field
                    type="text"
                    className="form-control"
                    id="nomorTelepon"
                    name="nomorTelepon"
                    as={ControlledInput}
                    pattern="\+?(?:[ -]?\d+)+|(\d+)(?:[ -]\d+)"
                    placeholder="<nomor telepon>"
                    value={formik.values.nomorTelepon}
                  />
                  <label htmlFor="nomorTelepon"> No. Hp. </label>
                  <ErrorMessage
                    name="nomorTelepon"
                    component={TextError}
                    className="text-warning"
                  />
                </form>
              </Col>
              <Col>
                <form className="form-floating">
                  <Field
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    as={ControlledInput}
                    pattern="^(?!.{101})[a-z0-9-_.+]+@[a-z0-9]+[a-z0-9-.]*\.[a-z0-9]{2,9}"
                    placeholder="<email>"
                    value={formik.values.email}
                  />
                  <label htmlFor="email"> Email </label>
                  <ErrorMessage
                    name="email"
                    component={TextError}
                    className="text-warning"
                  />
                </form>
              </Col>
            </Row>
            <Row className="text-primary mt-1">
              <Col md={6} sm={3} xs={3}>
                <CustomSelect
                  options={kkList}
                  id={"nomorKK"}
                  name="nomorKK"
                  value={formik.values.nomorKK}
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  placeholder="Pilih KK..."
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={6} sm={3} xs={3}>
                <form className="form-floating">
                  <Field
                    type="text"
                    className="form-control"
                    id="nomorKTP"
                    name="nomorKTP"
                    as={ControlledInput}
                    pattern="^[0-9]{16}$"
                    placeholder="<nomor KTP>"
                    value={formik.values.nomorKTP}
                  />
                  <label htmlFor="nomorKTP"> Nomor KTP </label>
                  <ErrorMessage
                    name="nomorKTP"
                    component={TextError}
                    className="text-warning"
                  />
                </form>
              </Col>
            </Row>
            <hr className="mb-0 pb-0" />
          </div>
        );
      }}
    </Formik>
  );

  return content;
};

export default Kontak;
