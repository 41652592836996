import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";

import { Formik } from "formik";
import CustomSelect from "../../components/form/CustomSelect";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  useAnggotaList,
  useTipeAnggotaList,
  useStatusAnggotaList,
} from "../../components/data/UseDataComponents";
import useFormContext from "../../hooks/useFormContext";

// ? mark pakai useRef dan forwarding ref

let tipeAnggota = "";
let statusAnggota = "";
let idStatusAnggota = 0;
let idTipeAnggota = 0;

function CariAnggota() {
  const { auth } = useAuth();
  const idKoperasi = auth.authIdKoperasi;
  // const selectReference = useRef(null)
  // const buttonReference = useRef(null)

  const { setPage, setData } = useFormContext();

  const [anggota, setAnggota] = useState();
  const [show, setShow] = useState(false);

  const { data: anggotaList } = useAnggotaList(idKoperasi);
  const { data: tipeAnggotaList } = useTipeAnggotaList();
  const { data: statusAnggotaList } = useStatusAnggotaList();

  // function checkPRess(e) {
  //   if(e.keyCode === 13) {
  //     buttonReference.current.focus()
  //   }
  // }

  const handleClose = () => {
    setShow(false);
    setAnggota();
    tipeAnggota = "";
    statusAnggota = "";
    idStatusAnggota = 0;
    idTipeAnggota = 0;
  };
  const handleShow = () => setShow(true);

  const handleChange = (value) => {
    tipeAnggota =
      value.value.idTipeAnggota && tipeAnggotaList
        ? tipeAnggotaList.find((obj) => {
            return obj.value === value.value.idTipeAnggota;
          }).label
        : "N/A";
    statusAnggota =
      value.value.idStatusAnggota && statusAnggotaList
        ? statusAnggotaList.find((obj) => {
            return obj.value === value.value.idStatusAnggota;
          }).label
        : "N/A";
    idTipeAnggota = value.value.idTipeAnggota;
    idStatusAnggota = value.value.idStatusAnggota;
  };

  const isiData = () => {
    setData({
      nomorAnggota: anggota?.value ? anggota.value : "",
      namaDepan: anggota?.namaDepan ? anggota.namaDepan : "",
      namaBelakang: anggota?.namaBelakang ? anggota.namaBelakang : "",
      jenisKelamin: anggota?.jenisKelamin ? anggota.jenisKelamin : "",
      tempatLahir: anggota?.tempatLahir ? anggota.tempatLahir : "",
      tanggalLahir: anggota?.tanggalLahir ? anggota.tanggalLahir : "",
      idAgama: anggota?.idAgama ? anggota.idAgama : "",
      idStatusPerkawinan: anggota?.idStatusPerkawinan
        ? anggota.idStatusPerkawinan
        : "",
      tanggalDaftar: anggota?.tanggalDaftar ? anggota.tanggalDaftar : "",
      idTipeAnggota: anggota?.idTipeAnggota ? anggota.idTipeAnggota : "",
      tipeAnggota:
        anggota?.idTipeAnggota && tipeAnggotaList
          ? tipeAnggotaList.find((obj) => {
              return obj.value === anggota.idTipeAnggota;
            }).label
          : "N/A",
      pengurus: anggota?.pengurus ? anggota.pengurus : "",
      pegawai: anggota?.pegawai ? anggota.pegawai : "",
      tanggalMasuk: anggota?.tanggalMasuk ? anggota.tanggalMasuk : "",
      idWilayah: anggota?.idWilayah ? anggota.idWilayah : "",
      idKelompokWilayah: anggota?.idKelompokWilayah
        ? anggota.idKelompokWilayah
        : "",
      nomorTelepon: anggota?.nomorTelepon ? anggota.nomorTelepon : "",
      email: anggota?.email ? anggota.email : "",
      nomorKK: anggota?.nomorKK ? anggota.nomorKK : "",
      nomorKTP: anggota?.nomorKTP ? anggota.nomorKTP : "",
      idGolonganDarah: anggota?.idGolonganDarah ? anggota.idGolonganDarah : "",
      idPekerjaan: anggota?.idPekerjaan ? anggota.idPekerjaan : "",
      tempatBekerja: anggota?.tempatBekerja ? anggota.tempatBekerja : "",
      idPenghasilan: anggota?.idPenghasilan ? anggota.idPenghasilan : "",
      idPendidikan: anggota?.idPendidikan ? anggota.idPendidikan : "",
      jurusan: anggota?.jurusan ? anggota.jurusan : "",
      namaSekolah: anggota?.namaSekolah ? anggota.namaSekolah : "",
      tahunLulus: anggota?.tahunLulus ? anggota.tahunLulus : "",
      idStatusDalamKeluarga: anggota?.idStatusDalamKeluarga
        ? anggota.idStatusDalamKeluarga
        : "",
      idStatusAnggota: anggota?.idStatusAnggota ? anggota.idStatusAnggota : "",
      statusAnggota:
        anggota?.idStatusAnggota && statusAnggotaList
          ? statusAnggotaList.find((obj) => {
              return obj.value === anggota.idStatusAnggota;
            }).label
          : "N/A",
      tanggalStatusAnggota: anggota?.tanggalStatusAnggota
        ? anggota.tanggalStatusAnggota
        : "",
      keterangan: anggota?.keterangan ? anggota.keterangan : "",
      tanggalDibuat: anggota?.tanggalDibuat ? anggota.tanggalDibuat : "",
      idUserPembuat: anggota?.idUserPembuat ? anggota.idUserPembuat : "",
      namaUserPembuat: anggota?.namaUserPembuat ? anggota.namaUserPembuat : "",
      tanggalDiubah: anggota?.tanggalDiubah ? anggota.tanggalDiubah : "",
      idUserPengubah: anggota?.idUserPengubah ? anggota.idUserPengubah : "",
      namaUserPengubah: anggota?.namaUserPengubah
        ? anggota.namaUserPengubah
        : "",
      screenMode: "Mode Edit Data",
    });
    handleClose();
  };

  const initData = () => {
    setData({
      nomorAnggota: "",
      namaDepan: "",
      namaBelakang: "",
      jenisKelamin: "",
      tempatLahir: "",
      tanggalLahir: "",
      idAgama: "",
      idStatusPerkawinan: "",
      tanggalDaftar: "",
      idTipeAnggota: "",
      pengurus: "",
      pegawai: "",
      tanggalMasuk: "",
      idWilayah: "",
      idKelompokWilayah: "",
      nomorTelepon: "",
      email: "",
      nomorKK: "",
      nomorKTP: "",
      idGolonganDarah: "",
      idPekerjaan: "",
      tempatBekerja: "",
      idPenghasilan: "",
      idPendidikan: "",
      jurusan: "",
      namaSekolah: "",
      tahunLulus: "",
      idStatusDalamKeluarga: "",
      idStatusAnggota: "",
      tanggalStatusAnggota: "",
      keterangan: "",
      foto: "",
      tanggalDibuat: "",
      idUserPembuat: "",
      namaUserPembuat: "",
      tanggalDiubah: "",
      idUserPengubah: "",
      namaUserPengubah: "",
      screenMode: "Mode Tambah Data",
    });
    setPage(0);
  };

  return (
    <Formik>
      {(formik) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-outline-primary btn-info border border-danger border-3 rounded-3 fst-italic fw-bold"
              onClick={initData}
            >
              <strong>
                <i className="text-primary bi bi-person-plus"></i> &nbsp; Tambah
                Anggota
              </strong>
            </button>

            <Button
              type="button"
              className="btn btn-outline-info border border-danger border-3 rounded-3 fw-bold fst-italic me-5"
              onClick={handleShow}
            >
              <strong>
                <i className="text-info bi bi-search"></i> &nbsp; Cari Anggota
              </strong>
            </Button>

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title> Cari nomor induk / nama anggota </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomSelect
                  options={anggotaList}
                  // ref={selectReference}
                  // autoFocus
                  // onKeyPress={(e) => checkPRess(e)}
                  // value={anggota ? anggota : ''}
                  value={anggota}
                  name="noIdAnggota"
                  onChange={(value) => {
                    setAnggota(value.value);
                    handleChange(value);
                  }}
                  onBlur={() => {}}
                  clearable={false}
                  className="mb-1 w-100"
                />
                {(tipeAnggota || statusAnggota) && (
                  <>
                    <Button className="btn btn-sm btn-info my-0 py-0 border-2 border-warning disabled">
                      {"Info"}
                    </Button>
                    <i className="bi bi-arrow-right-circle-fill text-info align-middle"></i>
                    &nbsp; &nbsp;
                    <Button
                      className={"btn btn-sm my-0 py-0 border-2 border-warning disabled".concat(
                        idTipeAnggota > 0 ? " btn-primary" : " btn-danger"
                      )}
                    >
                      Tipe : {tipeAnggota ? tipeAnggota : "N/A"}
                    </Button>
                    &nbsp;
                    <Button
                      className={"btn btn-sm my-0 py-0 border-2 border-warning disabled".concat(
                        idStatusAnggota > 2 ? " btn-danger" : " btn-primary"
                      )}
                    >
                      Status : {statusAnggota ? statusAnggota : "N/A"}
                    </Button>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-outline-dark btn-light rounded"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  // ref={buttonReference}
                  className="btn-outline-success btn-primary text-white rounded"
                  onClick={isiData}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}

export default CariAnggota;
