import React from "react"
import { Link } from "react-router-dom"

const Missing = () => {
  return (
    <div className="mt-5">
      <h1 className="mt-3"> Oops! </h1>
      <p className="mt-3"> Halaman tidak ditemukan </p>
      <div className="btn fw-bold border-3 border-warning mt-5 bg-info">
        <Link to="/"> Halaman Login </Link>
      </div>
    </div>
  )
}

export default Missing