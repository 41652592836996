import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

const CustomSelect = ({ options, value, id, name, placeholder, onChange, onBlur, disableOpt, disableSelect, clearable, stylesOpt, menuPortalOpt, className, specialStyle }) => {
  function customTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: 'orange',
        primary: 'green',
      },
    }
  }

  const defaultValue = (options, value) => {
    return(
      options 
        ? options.find(option => option.value === value) === undefined
          ? options.find(option => option.value === value?.value) // ""
          : options.find(option => option.value === value) 
        : ""
    ) 
  }

  return(
    <div className={className}>
      <Select
        options={options}
        value={defaultValue(options, value)}
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={value => onChange({value, name})}
        onBlur={id => onBlur(id, true)}
        isOptionDisabled={disableOpt}
        theme={customTheme}
        components={makeAnimated()}
        noOptionsMessage={() => 'Tidak ada data ...'}
        isDisabled={disableSelect}
        isSearchable
        isClearable={clearable}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    </div>
  )
}

export default CustomSelect