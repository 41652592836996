import React, { useRef, useEffect, useState } from "react";

import "./login.scss";

import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useInput from "../../hooks/useInput";
import useToggle from "../../hooks/useToggle";

import { Row, Col } from "react-bootstrap";
import Logo from "../../images/logo.png";
import axios from "../../api/axios";

const totalImageBackground = 12;
const num = Math.ceil(Math.random() * totalImageBackground);
const bgImg = "bg-dw" + num;

const LOGIN_URL = process.env.REACT_APP_API_KEY + "/auth/login";
const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const from = "/dashboard";

  const userRef = useRef();
  const errRef = useRef();

  const [userEmail, resetUserEmail, userEmailAttribs] = useInput("userEmail");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [check, toggleCheck] = useToggle("persist", false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [userEmail, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        { userEmail, password },
        // const response = await axios.post(
        //   LOGIN_URL,
        //   { userEmail: "a@b.com", password: "z" },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const results = response?.data?.results[0];
      if (
        results?.statusUser !== "Aktif" &&
        results?.aksesKP === 0 &&
        results.statusAksesKP !== "Aktif"
      ) {
        setErrMsg("Tidak memiliki otoritas");
        errRef.current.focus();
      } else {
        const authData = {
          authIdUser: results?.idUser,
          authEmail: results?.userEmail,
          authNamaUser: results?.namaUser,
          authInisial: results?.inisial,
          authIdKoperasi: results?.idKoperasi,
          authNamaKoperasi: results?.namaKoperasi,
          authNamaKotaKabupaten: results?.namaKotaKabupaten,
          authLevelUser: results?.levelUser,
          authTahunMulai: results?.tahunMulai,
          authTahunAkhir: results?.tahunAkhir,
          authAuthenticated: true,
          authRoles: results?.ijinAplikasi,
          authLevelAksesKP: results?.levelAksesKP,
          authRoleAksesKP: results?.roleAksesKP,
          accessToken: results?.accessToken,
        };

        setAuth(authData);

        resetUserEmail();
        setPassword("");
        navigate(from, { replace: true });
      }
    } catch (err) {
      console.log("error: ", err);
      if (!err?.response) {
        setErrMsg("Tidak ada respon dari server");
      } else if (err.response?.status === 400) {
        setErrMsg("Email atau Password salah");
      } else if (err.response?.status === 401) {
        setErrMsg("Tidak punya otoritas");
      } else {
        setErrMsg("Login gagal");
      }
      errRef.current.focus();
    }
  };

  return (
    <div className={bgImg.concat(" bg-container opacity-75 fullscreen")}>
      <Row>
        <Col className="w-30"></Col>
        <Col className="w-40 mt-5">
          <div className="form-group text-center border-warning mt-5">
            <p
              ref={errRef}
              className={
                errMsg
                  ? "btn text-warning border-5 border-danger bg-primary"
                  : ""
              }
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <form onSubmit={handleSubmit}>
              <img
                src={Logo}
                width="60%"
                className="opacity-75 shadow rounded-5 img-thumbnail border-primary border-5 mb-3"
                alt="KOP"
              ></img>
              <h1 className="text-warning fw-bold bg-success opacity-75 bg-opacity-100 rounded-2">
                Login
              </h1>
              <div className="opacity-75 username form-group input-group my-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="bi bi-envelope text-primary form-control"></i>
                </span>
                <input
                  type="text"
                  id="userEmail"
                  ref={userRef}
                  className="form-control focus-border-warning"
                  placeholder="Enter Email"
                  autoComplete="off"
                  {...userEmailAttribs}
                  required
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="opacity-75 password form-group input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="bi bi-unlock-fill text-primary form-control"></i>
                </span>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                  aria-describedby="basic-addon1"
                />
              </div>
              <button
                type="summit"
                className="btn btn-outline-warning border-4 text-primary fw-bold fs-5 mt-3"
              >
                Sign in
              </button>
              <div className="persistCheck mt-3">
                <input
                  type="checkbox"
                  id="persist"
                  onChange={toggleCheck}
                  checked={check}
                />
                <label htmlFor="persist" className="ms-1 fw-bold glow">
                  {" "}
                  Trust This Device{" "}
                </label>
              </div>
            </form>
          </div>
        </Col>
        <Col className="w-30"></Col>
      </Row>
    </div>
  );
};

export default Login;
