import React from 'react'
import { DropdownButton } from 'react-bootstrap'

import './Dropdown.css'

const Dropdowns = props => {

  return (
    <div>
      <DropdownButton
        id="dropdown-icon-button"
        title={
          <i className={props.icon}>
            {
              props.badge === '-' ? '' :
                <span className='dropdown__toggle-badge'>
                  {props.badge}
                </span>
            }
          </i>
        }
        variant="transparent"
        size="lg"
      >
        {
          props.contentData && props.renderItems && props.contentData.map(
            (item, index) => props.renderItems(item, index)
          )
        }
      </DropdownButton>
    </div>
  )
}

export default Dropdowns