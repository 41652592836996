import React, {useState} from 'react' 
import {FaArrowCircleUp} from 'react-icons/fa' 

const ScrollButton = () => { 

  const [visible, setVisible] = useState(false) 

  const toggleVisible = () => { 
    const scrolledBody = document.body.scrollTop 
    const scrolledElement = document.documentElement.scrollTop 
    if (scrolledBody > 300 || scrolledElement > 300){ 
      setVisible(true) 
    } 
    else if (scrolledBody <= 300 || scrolledElement <= 300){ 
      setVisible(false) 
    } 
  } 

  const scrollToTop = () => { 
    window.scrollTo({ 
    top: 0, 
    behavior: 'smooth'
    /* you can also use 'auto' behaviour 
        in place of 'smooth' */
    }) 
  } 

  window.addEventListener('scroll', toggleVisible); 

  return ( 
    <button 
      onClick={scrollToTop} 
      className={'btn btn-sm fixed-bottom btn-primary bg-transparent text-dark text-end border-0 z-3'.concat(visible ? ' d-block' : ' d-none')}
    > 
      <>
      <FaArrowCircleUp className='fs-2' /> 
      </>
    </button>
  ) 
} 

export default ScrollButton 