import React, { useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import { Formik, Field, ErrorMessage } from "formik";
import TextError from "../../components/form/TextError";
import { Row, Col } from "react-bootstrap";
import useFormContext from "../../hooks/useFormContext";
import CustomSelect from "../../components/form/CustomSelect";
import { PribadiWargaValidationSchema } from "../../components/data/ValidationSchema";
import {
  useGolonganDarahList,
  usePekerjaanList,
  usePenghasilanList,
  usePendidikanList,
  useStatusDalamKeluargaList,
} from "../../components/data/UseDataComponents";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import id from "date-fns/locale/id";
import ControlledInput from "./ControlledInput";
import Effect from "./Effect";

registerLocale("id", id);

const Pribadi = () => {
  const pribadiRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { auth } = useAuth();
  const idKoperasi = auth.authIdKoperasi;

  const { data, handleChange, setValidatePribadi } = useFormContext();

  const { data: golonganDarahList } = useGolonganDarahList();
  const { data: pekerjaanList } = usePekerjaanList();
  const { data: penghasilanList } = usePenghasilanList(idKoperasi);
  const { data: pendidikanList } = usePendidikanList();
  const { data: statusDalamKeluargaList } = useStatusDalamKeluargaList();

  const initialValues = {
    idKoperasi: idKoperasi,
    idGolonganDarah: data.idGolonganDarah,
    idPekerjaan: data.idPekerjaan,
    tempatBekerja: data.tempatBekerja,
    idPenghasilan: data.idPenghasilan,
    idPendidikan: data.idPendidikan,
    jurusan: data.jurusan,
    namaSekolah: data.namaSekolah,
    tahunLulus: data.tahunLulus,
    idStatusDalamKeluarga: data.idStatusDalamKeluarga,
    namaDepan: data.namaDepan, // mandatory
    namaBelakang: data.namaBelakang,
    tipeAnggota: data.tipeAnggota,
    idStatusAnggota: data.idStatusAnggota,
    statusAnggota: data.statusAnggota,
    screenMode: data.screenMode,
  };

  const content = (
    <Formik
      initialValues={initialValues}
      validationSchema={PribadiWargaValidationSchema}
      innerRef={pribadiRef}
      validateOnMount
      enableReinitialize
    >
      {(formik) => {
        return (
          <div className="container w-100 mx-0 px-0">
            <Effect
              onChange={(currentFormikState, nextFormikState) => {
                setValidatePribadi(formik.isValid);
              }}
            />
            <span className="text-success fw-bold">
              {formik.values.namaDepan ? formik.values.namaDepan.trim() : ""}
              {formik.values.namaBelakang
                ? " ".concat(formik.values.namaBelakang.trim())
                : ""}
            </span>
            <span className="text-primary fw-bold">
              {formik.values.tipeAnggota
                ? " * Tipe Anggota: ".concat(formik.values.tipeAnggota)
                : ""}
            </span>
            <span
              className={
                formik.values.idStatusAnggota < 2
                  ? "text-info fw-bold"
                  : "text-danger fw-bold"
              }
            >
              {formik.values.statusAnggota
                ? " * Status : ".concat(formik.values.statusAnggota)
                : ""}
            </span>
            <hr className="mt-0 pt-0" />
            <Row className="mt-2 text-primary">
              <Col md={2} sm={1} xs={1}>
                <label htmlFor="idGolonganDarah"> Golongan Darah </label>
              </Col>
              <Col md={3} sm={1} xs={1}>
                <label htmlFor="idPekerjaan"> Pekerjaan </label>
              </Col>
              <Col md={4} sm={3} xs={3}>
                <label htmlFor="tempatBekerja"> Tempat Bekerja </label>
              </Col>
              <Col md={3} sm={3} xs={3}>
                <label htmlFor="idPenghasilan"> Range Penghasilan </label>
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={1} xs={1}>
                <CustomSelect
                  options={golonganDarahList}
                  id={"idGolonganDarah"}
                  value={parseInt(formik.values.idGolonganDarah, 10)}
                  name="idGolonganDarah"
                  placeholder="Select..."
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={3} sm={1} xs={1}>
                <CustomSelect
                  options={pekerjaanList}
                  id={"idPekerjaan"}
                  value={parseInt(formik.values.idPekerjaan, 10)}
                  name="idPekerjaan"
                  placeholder="Select..."
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={4} sm={2} xs={2}>
                <Field
                  type="text"
                  className="form-control"
                  id="tempatBekerja"
                  name="tempatBekerja"
                  as={ControlledInput}
                  pattern="([A-Z])[\w+-/().' ']{1,49}"
                  value={formik.values.tempatBekerja}
                />
                <ErrorMessage
                  name="tempatBekerja"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
              <Col md={3} sm={2} xs={2}>
                <CustomSelect
                  options={penghasilanList}
                  id={"idPenghasilan"}
                  value={parseInt(formik.values.idPenghasilan, 10)}
                  name="idPenghasilan"
                  placeholder="Select..."
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
            </Row>
            <Row className="mt-3 text-primary">
              <Col md={2} sm={2} xs={2}>
                <label htmlFor="idPendidikan"> Pendidikan Terakhir </label>
              </Col>
              <Col md={3} sm={2} xs={2}>
                <label htmlFor="jurusan"> Jurusan </label>
              </Col>
              <Col md={4} sm={3} xs={3}>
                <label htmlFor="namaSekolah"> Nama Sekolah </label>
              </Col>
              <Col md={2} sm={2} xs={2}>
                <label htmlFor="tahunLulus"> Tahun Lulus </label>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2} sm={2} xs={2}>
                <CustomSelect
                  options={pendidikanList}
                  id={"idPendidikan"}
                  value={parseInt(formik.values.idPendidikan, 10)}
                  name="idPendidikan"
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
              <Col md={3} sm={3} xs={3}>
                <Field
                  type="text"
                  className="form-control"
                  id="jurusan"
                  name="jurusan"
                  as={ControlledInput}
                  pattern="([A-Z])[\w+-/().' ']{1,49}"
                  value={formik.values.jurusan}
                />
                <ErrorMessage
                  name="jurusan"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
              <Col md={4} sm={3} xs={3}>
                <Field
                  type="text"
                  className="form-control"
                  id="namaSekolah"
                  name="namaSekolah"
                  as={ControlledInput}
                  pattern="([A-Z])[\w+-/().' ']{1,49}"
                  value={formik.values.namaSekolah}
                />
                <ErrorMessage
                  name="namaSekolah"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
              <Col md={2} sm={2} xs={2}>
                <DatePicker
                  selected={
                    (formik.values?.tahunLulus &&
                      new Date(formik.values.tahunLulus)) ||
                    null
                  }
                  value={
                    formik.values?.tahunLulus &&
                    moment(formik.values.tahunLulus).format("YYYY")
                  }
                  locale="id"
                  onChange={(date) =>
                    handleChange({
                      value: date ? moment(date).format("YYYY") : "",
                      name: "tahunLulus",
                    })
                  }
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  minDate={new Date("1910-01-01")}
                  maxDate={new Date()}
                  placeholderText="Select..."
                  showYearPicker
                  dateFormat="yyyy"
                  yearItemNumber={9}
                  isClearable
                  className="date-picker-wrapper"
                />
                <ErrorMessage
                  name="tahunLulus"
                  component={TextError}
                  className="text-warning"
                />
              </Col>
            </Row>
            <Row className="mt-3 text-primary">
              <Col className="form-group text-primary" md={3} sm={2} xs={2}>
                <label htmlFor="idStatusDalamKeluarga">
                  {" "}
                  Status di Keluarga{" "}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={2} xs={2}>
                <CustomSelect
                  options={statusDalamKeluargaList}
                  id={"idStatusDalamKeluarga"}
                  value={parseInt(formik.values.idStatusDalamKeluarga, 10)}
                  name="idStatusDalamKeluarga"
                  onChange={handleChange}
                  onBlur={() => {}}
                  // disableSelect={fieldDisable}
                  clearable={true}
                  className="mb-1 w-100"
                />
              </Col>
            </Row>
          </div>
        );
      }}
    </Formik>
  );

  return content;
};

export default Pribadi;
