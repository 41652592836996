import React, { Fragment } from "react";
import "./App.css";

import Login from "./pages/login/Login";
import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import ProgressBar from "./pages/anggota/ProgressBar";
import Form from "./pages/anggota/Form";
import { FormProvider } from "./contexts/FormContext";
// import ProfileWarga from "./pages/profilewarga/ProfileWarga";
// import InfoWarga from "./pages/infowarga/InfoWarga";
// import Laporan from "./pages/laporan/Laporan";
// import DataPendukung from "./pages/datapendukung/DataPendukung";
// import TabelPendukung from "./pages/tabelpendukung/TabelPendukung";
import MaintUser from "./pages/user/MaintUser";
import MaintUserAkses from "./pages/userakses/MaintUserAkses";
import ProfileUser from "./pages/profileuser/ProfileUser";
import RequiredAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Missing from "./components/Missing";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route element={<Layout />}>
          <Route element={<PersistLogin />}>
            <Route element={<RequiredAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/dataanggota"
                element={
                  <Fragment>
                    <FormProvider>
                      <ProgressBar />
                      <Form />
                    </FormProvider>
                    <Outlet />
                  </Fragment>
                }
              />
              {/* <Route path="/profilewarga" element={<ProfileWarga />} />
              <Route path="/infowarga" element={<InfoWarga />} />
              <Route path="/laporan" element={<Laporan />} />
              <Route path="/datapendukung" element={<DataPendukung />} />
              <Route path="/tabelpendukung" element={<TabelPendukung />} /> */}
              <Route path="/user" element={<MaintUser />} />
              <Route path="/userakses" element={<MaintUserAkses />} />
              <Route path="/profileuser" element={<ProfileUser />} />
            </Route>
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
