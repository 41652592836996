import React from 'react'
import { NumericFormat } from 'react-number-format'

import './StatusCard.css'

const StatusCard = props => {
	return (
		<div className='status-card'>
			<div className="status-card__icon">
				<i className={props.icon}></i>
			</div>
			<div className="status-card__info">
				<h4>
					<NumericFormat 
						displayType={'text'} 
						thousandSeparator={'.'} 
						decimalSeparator={','} 
						decimalScale={0} 
						fixedDecimalScale={true} 
						allowNegative={false}
						value={props.count}
					/>
				</h4>
				<span>{props.title}</span>
			</div>
		</div>
	)
}

export default StatusCard
