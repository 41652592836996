import React, { useEffect, useRef, useState } from 'react'
import useFormContext from "../../hooks/useFormContext"

const ControlledInput = (props) => {

  const { handleChange } = useFormContext()
  const { value, onChange, ...rest } = props
  const [cursor, setCursor] = useState(null)
  const ref = useRef(null)
  

  useEffect(() => {
    const input = ref.current
    if (input) input.setSelectionRange(cursor, cursor)
  }, [ref, cursor, value])

  const ganti = (e) => {
    setCursor(e.target.selectionStart)
    handleChange && handleChange(e)
  }

  return <input ref={ref} value={value} onChange={ganti} {...rest} />

}

export default ControlledInput