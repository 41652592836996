import React from "react";
import useAuth from "../../hooks/useAuth";
import { Row, Col } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import FormInputs from "./FormInputs";
import CariAnggota from "./CariAnggota";
import useFormContext from "../../hooks/useFormContext";
import "./form.scss";

require("moment/locale/id");
let config = "";

const Form = () => {
  const { auth } = useAuth();

  const apiUrlKP = process.env.REACT_APP_API_KEY + "/kop-anggota";

  const idKoperasi = auth.authIdKoperasi;
  const idUser = auth.authIdUser;

  const {
    title,
    page,
    setPage,
    data,
    canSubmit,
    disablePrev,
    disableNext,
    prevHide,
    nextHide,
    submitHide,
  } = useFormContext();

  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  const isiData = {
    idKoperasi: idKoperasi,
    nomorAnggota: data.nomorAnggota,
    namaDepan: data.namaDepan,
    namaBelakang: data.namaBelakang,
    jenisKelamin: data.jenisKelamin,
    tempatLahir: data.tempatLahir,
    tanggalLahir: data.tanggalLahir,
    idAgama: data.idAgama,
    idStatusPerkawinan: data.idStatusPerkawinan,
    tanggalDaftar: data.tanggalDaftar,
    idTipeAnggota: data.idTipeAnggota,
    pengurus: data.pengurus,
    pegawai: data.pegawai,
    tanggalMasuk: data.tanggalMasuk,
    idWilayah: data.idWilayah,
    idKelompokWilayah: data.idKelompokWilayah,
    nomorTelepon: data.nomorTelepon,
    email: data.email,
    nomorKK: data.nomorKK,
    nomorKTP: data.nomorKTP,
    idGolonganDarah: data.idGolonganDarah,
    idPekerjaan: data.idPekerjaan,
    tempatBekerja: data.tempatBekerja,
    idPenghasilan: data.idPenghasilan,
    idPendidikan: data.idPendidikan,
    jurusan: data.jurusan,
    namaSekolah: data.namaSekolah,
    tahunLulus: data.tahunLulus,
    idStatusDalamKeluarga: data.idStatusDalamKeluarga,
    idStatusAnggota: data.idStatusAnggota,
    tanggalStatusAnggota: data.tanggalStatusAnggota,
    keterangan: data.keterangan,
    foto: data.foto,
    tanggalDibuat: data.tanggalDibuat,
    idUserPembuat: data.idUserPembuat,
    namaUserPembuat: data.namaUserPembuat,
    tanggalDiubah: data.tanggalDiubah,
    idUserPengubah: data.idUserPengubah,
    namaUserPengubah: data.namaUserPengubah,
    idUser: idUser,
  };

  const addAnggota = () => {
    config = {
      method: "post",
      url: `${apiUrlKP}/anggota`,
      headers: {
        "Content-Type": "application/json",
      },
      data: isiData,
    };
    return axiosPrivate(config);
  };

  const useAddAnggota = () => {
    return useMutation(addAnggota, {
      onError: () => {
        toast.error("Data anggota gagal ditambah !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
      },
      onSuccess: (data) => {
        toast.success("Data anggota berhasil ditambah ...", data, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
        queryClient.invalidateQueries();
      },
    });
  };

  const updateAnggota = () => {
    const config = {
      method: "patch",
      url: `${apiUrlKP}/anggota/${idKoperasi}/${data.nomorAnggota}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: isiData,
    };
    return axiosPrivate(config);
  };

  const useUpdateAnggota = () => {
    return useMutation(updateAnggota, {
      onError: (err) => {
        toast.error("Data anggota gagal diubah !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
      },
      onSuccess: (data) => {
        toast.success("Data anggota berhasil diubah ...", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
        queryClient.invalidateQueries();
      },
    });
  };

  const handlePrev = () => setPage((prev) => prev - 1);
  const handleNext = () => setPage((prev) => prev + 1);

  const { mutate: addAgt } = useAddAnggota();
  const { mutate: updateAgt } = useUpdateAnggota();

  const handleSubmit = (e) => {
    e.preventDefault();
    data.screenMode.match("Tambah") && addAgt();
    data.screenMode.match("Edit") && updateAgt();
  };

  const content = (
    <div className="form flex-col" onSubmit={handleSubmit}>
      <header className="form-header">
        <h2>{title[page]}</h2>

        <h5 className="fw-bold">
          <small>
            <strong>
              <i
                className={"text-primary bi bi-display".concat(
                  data.screenMode.match("Edit") ? "-fill" : ""
                )}
                style={{ fontSize: 35 }}
              ></i>{" "}
              &nbsp;
              {data.screenMode}
            </strong>
          </small>
        </h5>

        <div className="button-container">
          <CariAnggota />

          <button
            type="button"
            className={`button btn ${prevHide} ${
              disablePrev
                ? "btn-light fw-bold border border-warning border-1"
                : "btn-primary border border-warning border-3"
            }`}
            onClick={handlePrev}
            disabled={disablePrev}
          >
            {" "}
            Prev{" "}
          </button>

          <button
            type="button"
            className={`button btn ${nextHide} ${
              disableNext
                ? "btn-light fw-bold border border-warning border-1"
                : "btn-primary border border-warning border-3"
            }`}
            onClick={handleNext}
            disabled={disableNext}
          >
            {" "}
            Next{" "}
          </button>

          <button
            type="button"
            className={`button btn ${submitHide} ${
              canSubmit
                ? "btn-success fw-bold border border-warning border-3"
                : "btn-light border border-warning border-1"
            }`}
            onClick={handleSubmit}
            disabled={!canSubmit}
          >
            {" "}
            Submit{" "}
          </button>
        </div>
      </header>

      <FormInputs />
      <ToastContainer />
      <hr className="mt-4 pb-0 mb-0" />
      <span className="fs-6 pt-0 mt-0">
        <Row>
          <Col md={9} className="text-start">
            <small>
              tanggal dibuat:{" "}
              {data?.tanggalDibuat
                ? moment(data?.tanggalDibuat).format("DD-MMMM-YYYY HH:MM:SS")
                : "-"}{" "}
              ; user: {data?.namaUserPembuat ? data.namaUserPembuat : "-"} / /
              tanggal diubah:{" "}
              {data?.tanggalDiubah
                ? moment(data?.tanggalDiubah).format("DD-MMMM-YYYY HH:MM:SS")
                : "-"}{" "}
              ; user: {data?.namaUserPengubah ? data.namaUserPengubah : "-"}
            </small>
          </Col>
          <Col md={3} className="text-end">
            <small>
              (<span className="strong text-danger fw-bold">*</span>) Field
              mandatory
            </small>
          </Col>
        </Row>
      </span>
    </div>
  );

  return content;
};

export default Form;
