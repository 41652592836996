import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './css/grid.css'
import './css/theme.css'
// eslint-disable-next-line
import bootstrap from 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './css/custom.min.css'
import App from './App'
import { AuthProvider } from './contexts/AuthProvider'
// import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './redux/reducers'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
// window.process = {}
const store = createStore(rootReducer, applyMiddleware(thunk))

// if (process.env.NODE_ENV === 'production') {
//   disableReactDevTools()
// }

const queryClient = new QueryClient()

document.title = 'GKJ-DW'

ReactDOM.render(
  // <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </AuthProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)
