import React, { useState, useEffect } from 'react'

import './Table.css'

let kiri = 0
let kanan = 0
let onLoad = 'Betul'

const Table = props => {

  useEffect(() => {
    kiri = 0
    kanan = 0
    onLoad = 'Betul'
  }, [])
  let pages = 1
  let range = []
  const initDataShow = props.limit && props.bodyData ? props.bodyData.slice(0, Number(props.limit)) : props.bodyData
  
  const [dataShow, setDataShow] = useState([initDataShow])
  let page = Math.floor(props.bodyData.length / Number(props.limit))
  pages = props.bodyData.length % Number(props.limit) === 0 ? page : page + 1
  range = pages > 0 ? [...Array(pages).keys()] : []

  const [currPage, setCurrPage] = useState(0)
  const [currRange, setCurrRange] = useState([])

  if (onLoad === 'Betul') {
    kanan = (pages > 20) ? 20 : (pages === 0 ? 0 : pages - 1)
    if (pages > 0) {
      onLoad =  'Tidak'
      setDataShow(props.bodyData.slice(0, Number(props.limit)))
      setCurrRange(range.slice(0, pages >= 20 ? 20 : pages))
    }
  }
  
  const selectPage = page => {
    const start = Number(props.limit) * page
    const end = start + Number(props.limit)
    setDataShow(props.bodyData.slice(start, end))
    setCurrPage(page)
  }

  const onMinus = () => {
    kiri = kiri === 0 ? kiri : kiri - 1
    kanan = kiri + 20 >= pages ? pages : kiri + 20
    setCurrRange(range.slice(kiri, kanan))
  }
  const onPlus = () => {
    kiri = kiri + 20 >= pages ? kiri : kiri + 1
    kanan = kiri + 20 >= pages ? pages : kanan + 1
    setCurrRange(range.slice(kiri, kanan))
  }

  return (
    <div>
      <div className="table-wrapper">
        <table>
          {
            props.headData && props.renderHead ? (
              <thead>
                <tr>
                  {
                    props.headData.map((item, index) => props.renderHead(item, index))
                  }
                </tr>
              </thead>
            ) : null
          }
          {
            props.bodyData && props.renderBody ? (
              <tbody>
                {
                  dataShow[0].map((item, index) => props.renderBody(item, index))
                }
              </tbody>
            ) : null
          }
        </table>
      </div>
      {
        pages > 1 ? (
          <div className="table__pagination">
            { (pages > 20 && kiri > 0) ? (
              <button 
                type="button" 
                className="btn btn-sm btn-outline-success bi bi-arrow-left-circle text-center me-2" 
                onClick={() => onMinus()}
              >
              </button>
            ) : null }
            {
              currRange.map((item, index) => (
                <div key={index} className={`table__pagination-item ${currPage === item ? 'active' : ''}`} onClick={() => selectPage(item)}>
                  {item + 1}
                </div>
              ))
            }
            { (pages > 20 && kanan < pages - 1) ? (
              <button 
                type="button" 
                className="btn btn-sm btn-outline-success bi bi-arrow-right-circle text-center ms-2" 
                onClick={() => onPlus()}
              >
              </button>
            ) : null }
          </div>
        ) : null
      }
    </div>
  )
}

export default Table
